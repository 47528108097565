import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import CountDown from '../../components/Countdown'
import { getSingleProduct, deleteProduct } from '../../redux/actions'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { ThreeDots } from 'react-loader-spinner'

// import p1 from '../../assets/img/ppv1.MP4'
// import EditProduct from './EditProduct'

const ProductDetails = ({
  getSingleProduct,
  singleProduct,
  deleting,
  deleteProduct
}) => {
  const { id } = useParams()

  useEffect(
    () => {
      getSingleProduct(id)
    },
    // eslint-disable-next-line
    []
  )

  const handleDelete = () => {
    deleteProduct(id)
  }
  // console.log(singleProduct)
  return (
    singleProduct && (
      <div className=' px-2'>
        <Row>
          <Col sm='12' md='6'>
            {singleProduct.file.slice(
              (Math.max(0, singleProduct.file.lastIndexOf('.')) || Infinity) + 1
            ) === ('jpg' || 'jpeg' || 'png') ? (
              <img
                src={singleProduct.file}
                width='100%'
                height='auto'
                alt='...'
              />
            ) : (
              <video
                height='500'
                className='w-100 p-0 bg-dark'
                controls
                autoPlay
                muted
              >
                <source src={singleProduct.file} />
                Your browser does not support the video tag
              </video>
            )}
          </Col>
          <Col sm='12' md='6'>
            <h3
              className=''
              style={{
                color: '#68402eea'
              }}
            >
              {singleProduct.name}
            </h3>
            <p>{singleProduct.description}</p>
            <p>
              Starting bid:{' '}
              <span className='fw-bold'>{`$ ${singleProduct.startingBidPrice}`}</span>{' '}
            </p>
            <div className='product-details-countdown mb-3 py-2 px-2 w-100'>
              <CountDown date={new Date(singleProduct.dueDate).getTime()} />
            </div>
            <p>
              Auction ends{' '}
              <span className='fw-bold'>
                {new Date(singleProduct.dueDate).toUTCString()}
              </span>
            </p>
            <NavLink
              to={`/admin/edit-details/${singleProduct._id}`}
              className='py-2 px-4 rounded text-white'
              style={{
                background: '#68402eea',
                color: 'white'
              }}
            >
              {' '}
              Edit
            </NavLink>

            <button
              className='btn btn-outline-danger '
              style={{
                marginLeft: '12px'
              }}
              onClick={handleDelete}
            >
              {deleting ? (
                <div className='text-center w-100 text-center'>
                  <ThreeDots
                    color='white'
                    height={'12px'}
                    wrapperStyle={{ display: 'block' }}
                  />
                </div>
              ) : (
                'Delete'
              )}
            </button>
          </Col>
        </Row>
        <table className=' w-100 mt-3'>
          <tr className='table-head mb-3'>
            <th>Bider Name</th>
            <th>Bider Email</th>
            <th>Bid Price</th>
            <th>Status</th>
            <th>Action</th>
          </tr>

          {singleProduct.bids.map(el => (
            <tr>
              <td>{`${el.user.firstName} ${el.user.lastName}`}</td>
              <td>{el.user.email}</td>
              <td>{el.biddingPrice}</td>
              <td
                className={
                  el.status === 'Pending'
                    ? 'text-warning'
                    : el.status === 'Approved'
                    ? 'text-success'
                    : 'text-danger'
                }
              >
                {el.status}
              </td>
              <td>
                <div className='d-flex'>
                  <button
                    style={{
                      marginRight: '12px'
                    }}
                    className='mr-auto btn-success px-3 py-1 rounded'
                  >
                    Approve
                  </button>

                  <button className='btn-danger px-3 py-1 rounded'>
                    Decline
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </table>
        {/* <EditProduct
          modalState={openModal}
          setModalState={toggleModal}
          product={singleProduct}
        /> */}
      </div>
    )
  )
}
const mapStateToProps = ({ product }) => {
  const { singleProduct, loading, deleting } = product
  return { singleProduct, loading, deleting }
}
export default connect(mapStateToProps, { getSingleProduct, deleteProduct })(
  ProductDetails
)
