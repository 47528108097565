import {
  BID_PRODUCT,
  BID_PRODUCT_SUCCESS,
  BID_PRODUCT_FAILED,
  GET_ALL_BIDS,
  GET_ALL_BIDS_SUCCESS,
  GET_ALL_BIDS_FAILED,
  GET_USER_BID,
  GET_USER_BID_SUCCESS,
  GET_USER_BID_FAILED,
  DELETE_BID,
  DELETE_BID_SUCCESS,
  DELETE_BID_FAILED,
  APPROVE_BID,
  APPROVE_BID_SUCCESS,
  APPROVE_BID_FAILED,
  DECLINE_BID,
  DECLINE_BID_SUCCESS,
  DECLINE_BID_FAILED
} from '../actions'

export const bidProduct = (id, price) => ({
  type: BID_PRODUCT,
  payload: { id, price }
})

export const bidProductSuccess = message => ({
  type: BID_PRODUCT_SUCCESS,
  payload: { message }
})

export const bidProductFailed = error => ({
  type: BID_PRODUCT_FAILED,
  payload: { error }
})

export const getAllBids = () => ({
  type: GET_ALL_BIDS
})

export const getAllBidsSuccess = data => ({
  type: GET_ALL_BIDS_SUCCESS,
  payload: { data }
})

export const getAllBidsFailed = error => ({
  type: GET_ALL_BIDS_FAILED,
  payload: { error }
})

export const getUserBid = id => ({
  type: GET_USER_BID,
  payload: { id }
})

export const getUserBidSuccess = data => ({
  type: GET_USER_BID_SUCCESS,
  payload: { data }
})

export const getUserBidFailed = error => ({
  type: GET_USER_BID_FAILED,
  payload: { error }
})

export const deleteBid = id => ({
  type: DELETE_BID,
  payload: { id }
})

export const deleteBidSuccess = message => ({
  type: DELETE_BID_SUCCESS,
  payload: { message }
})

export const deleteBidFailed = error => ({
  type: DELETE_BID_FAILED,
  payload: { error }
})

export const approveBid = id => ({
  type: APPROVE_BID,
  payload: { id }
})

export const approveBidSuccess = message => ({
  type: APPROVE_BID_SUCCESS,
  payload: { message }
})

export const approveBidFailed = error => ({
  type: APPROVE_BID_FAILED,
  payload: { error }
})

export const declineBid = id => ({
  type: DECLINE_BID,
  payload: { id }
})

export const declineBidSuccess = message => ({
  type: DECLINE_BID_SUCCESS,
  payload: { message }
})

export const declineBidFailed = error => ({
  type: DECLINE_BID_FAILED,
  payload: { error }
})
