import React from 'react'
import { Row, Col, Card } from 'reactstrap'
import CountDown from '../../components/Countdown'

import p1 from '../../assets/img/pp26.JPG'
import p2 from '../../assets/img/pp18.JPG'
import p3 from '../../assets/img/pp3.JPG'
import p7 from '../../assets/img/pp7.JPG'
import p4 from '../../assets/img/pp4.JPG'

const Upcoming = ({ products }) => {
  return (
    <div
      className='px-md-5 px-4 py-3'
      style={{
        background: 'rgba(250,250,248,0.5)'
      }}
    >
      <div className='mb-4'>
        <h3>Upcoming</h3>
        <p>See what's popular across thousands of items.</p>
      </div>

      <Row>
        {products.map(el => {
          return (
            <Col sm='12' md='4' className='mb-4 '>
              <Card className='overflow-hidden zoom-container'>
                <img
                  className='zoom w-100'
                  src={el.file}
                  alt='...'
                  height='400px'
                />
                <div className=''>
                  <div className='product-countdown py-3 px-2 ml-5 w-100'>
                    <CountDown date={new Date(el.dueDate).getTime()} />
                  </div>
                </div>
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default Upcoming
