import React, { useEffect } from 'react'
import { Row, Card, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { getAnalytics } from '../../redux/actions'

const Dashboard = ({ getAnalytics, analytics }) => {
  useEffect(() => {
    getAnalytics()
  }, [])

  useEffect(() => {
    console.log(analytics)
  }, [analytics])

  return (
    <div className='dashboard-section'>
      <Row>
        <Col className='' sm='12' md='3'>
          <Card className='dashboard-card'>
            <p className='card-value mb'>
              <span className='card-curr'>$</span> {analytics?.totalAmount}
              <span
                style={{
                  fontSize: '14px'
                }}
              >
                :00
              </span>
            </p>
            <p className='card-title '>Total Balance</p>
          </Card>
        </Col>
        <Col className='' sm='12' md='3'>
          <Card className='dashboard-card'>
            <p className='card-value'>{analytics?.users}</p>
            <p className='card-title'>Total User</p>
          </Card>
        </Col>
        <Col className='' sm='12' md='3'>
          <Card className='dashboard-card'>
            <p className='card-value'>{analytics?.product}</p>
            <p className='card-title'>Total Products</p>
          </Card>
        </Col>
        <Col className='' sm='12' md='3'>
          <Card className='dashboard-card'>
            <p className='card-value'>0</p>
            <p className='card-title'>Models</p>
          </Card>
        </Col>
      </Row>

      <div className='mt-5'>
        <Row>
          <Col className='' sm='12' md='12'>
            <p className='section-title mb-0'>Transactions history</p>
            <table className=' w-100'>
              <tr className='table-head mb-3'>
                <th>Product</th>
                <th>Bid Price</th>
                <th>Price Sold</th>
                <th>Buyer</th>
                <th>Date</th>
              </tr>

              {analytics?.transactions.map(el => (
                <tr>
                  <td>{el.product.name}</td>
                  <td>${el.product.startingBidPrice}</td>
                  <td>${el.amount}</td>
                  <td>{`${el.user.firstName} ${el.user.lastName}`}</td>
                  <td>23/05/2022</td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const mapStateToProps = ({ transaction }) => {
  const { analytics } = transaction
  return { analytics }
}

export default connect(mapStateToProps, { getAnalytics })(Dashboard)
