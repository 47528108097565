import axios from 'axios'

const BASE_URL = 'https://pristine-v1.vercel.app/'

export const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

// export const setAuthToken = token => {
//   console.log(token)
//   if (token) {
//     // axios.defaults.headers.common['X-CSRFToken'] = token;
//     // axios.defaults.headers.common['Authorization'] = token;
//     Axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
//     Axios.defaults.headers.common = { Authorization: `bearer ${token}` }
//   } else {
//     // delete axios.defaults.headers.common['X-CSRFToken'];
//     delete Axios.defaults.headers.common['Authorization']
//   }
// }

export const setCurrentUser = (user, token) => {
  console.log(user, token)
  try {
    if (user && token) {
      localStorage.setItem('pristineToken', token)
      localStorage.setItem('pristineUser', JSON.stringify(user))
    } else {
      localStorage.removeItem('pristineToken')
      localStorage.removeItem('pristinerUser')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error)
  }
}

export const categories = [
  {
    label: 'Wrist watches',
    value: 'Wrist watches'
  },
  {
    label: 'Chains',
    value: 'Chains'
  },
  {
    label: 'Trinkets',
    value: 'Trinkets'
  },
  {
    label: 'Rings',
    value: 'Rings'
  },
  {
    label: 'Earrings',
    value: 'Earrings'
  }
]

export const type = [
  {
    label: 'Past',
    value: 'Past'
  },
  {
    label: 'Upcoming',
    value: 'Upcoming'
  },
  {
    label: 'Trending',
    value: 'Trending'
  }
]
