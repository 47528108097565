import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import product from '../../assets/img/pp19.JPG'
import { getAllBids, approveBid, declineBid } from '../../redux/actions'
import { connect } from 'react-redux'

const User = ({ bid, getAllBids, allBids, approveBid, declineBid }) => {
  useEffect(() => {
    getAllBids()
    // eslint-disable-next-line
  }, [])

  console.log(allBids)

  return (
    <div>
      <div className='mt-5'>
        <Row>
          <Col className='' sm='12' md='12'>
            <table className=' w-100'>
              <tr className='table-head mb-3'>
                <th>Image</th>
                <th>Name</th>
                <th>Category</th>
                <th>Starting Price</th>
                <th>Bid Price</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              {allBids.map(el => (
                <tr>
                  <td>
                    <div
                      style={{
                        width: '70px',
                        height: '70px'
                      }}
                    >
                      <img src={el?.product.file} alt='...' width='70px' />
                    </div>
                  </td>
                  <td>{el?.product.name}</td>
                  <td>{el?.product.category}</td>
                  <td>{`$${el?.product.startingBidPrice}`}</td>
                  <td>{`$${el?.biddingPrice}`}</td>
                  <td
                    className={
                      el.status === 'Pending'
                        ? 'text-warning'
                        : el.status === 'Approved'
                        ? 'text-success'
                        : 'text-danger'
                    }
                  >
                    {el.status}
                  </td>
                  <td>
                    <div className='d-flex justify-content-between'>
                      <button
                        className='btn-success px-3 py-1 rounded'
                        onClick={() => {
                          approveBid(el._id)
                        }}
                      >
                        Approve
                      </button>

                      <button
                        className='btn-danger px-3 py-1 rounded'
                        onClick={() => {
                          declineBid(el._id)
                        }}
                      >
                        Decline
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </div>
    </div>
  )
}
const mapStateToProps = ({ bid }) => {
  const { allBids, loading, deleting } = bid
  return { allBids, loading, deleting }
}
export default connect(mapStateToProps, { getAllBids, approveBid, declineBid })(
  User
)
