import React from 'react'
import { Row, Col } from 'reactstrap'
import Select from 'react-select'
import Footer from '../../components/UserLayout/Footer'

const Models = () => {
  return (
    <div className='about-section'>
      <div className='h-100vh hero-section'>
        {/* <div className='hero-cta-section'>
          <h3>About Us</h3>
          <div className='hero-form-section'></div>
        </div> */}
      </div>

      <div className='mt-3 px-md-5 px-3 text-small'>
        <p className='mt-5'>
          Founded in 2006, <span className='fw-bold'>PRISTINE PIECES</span> is a leading art and
          luxury business. Renowned and trusted for its expert live and online
          auctions, as well as its bespoke private sales,{' '}
          <span className='fw-bold'>PRISTINE PIECES</span> offers a full portfolio of global
          services to its clients, including art appraisal, art financing,
          international real estate and education.
          <span className='fw-bold'>PRISTINE PIECES</span> has a physical presence in many
          countries, throughout the Americas, Europe, Middle East, and Asia
          Pacific, with flagship international sales hubs in New York, London,
          Hong Kong, Paris and Geneva. It also is the only international auction
          house authorized to hold sales in mainland China (Shanghai).
        </p>
        <p>
          <span className='fw-bold'>PRISTINE PIECES</span>’s auctions span more than 80 art and
          luxury categories, at price points ranging from $200 to over $100
          million. In recent years, <span className='fw-bold'>PRISTINE PIECES</span> has achieved
          the world record price for an artwork at auction (Leonardo da Vinci’s
          Salvador Mundi, 2017), for a single collection sale (the Collection of
          Peggy and David Rockefeller, 2018), and for a work by a living artist
          (Jeff Koons’ Rabbit, 2019).
        </p>
        <p>
          <span className='fw-bold'>PRISTINE PIECES</span> Private Sales offers a seamless service
          for buying and selling art, jewellery and watches outside of the
          auction calendar, working exclusively with{' '}
          <span className='fw-bold'>PRISTINE PIECES</span> specialists at a client’s individual
          pace.
        </p>
        <p>
          Recent innovations at <span className='fw-bold'>PRISTINE PIECES</span> include the
          groundbreaking sale of the first NFT for a digital work of art ever
          offered at a major auction house (Beeple’s Everydays, March 2021),
          with the unprecedented acceptance of cryptocurrency as a means of
          payment. As an industry leader in digital innovation,{' '}
          <span className='fw-bold'>PRISTINE PIECES</span> also continues to pioneer new
          technologies that are redefining the business of art, including the
          creation of viewing and bidding experiences that integrate augmented
          reality, global livestreaming, buy-now channels, and hybrid sales
          formats.
        </p>

        <p className='mb-4'>
          <span className='fw-bold '>PRISTINE PIECES</span> is dedicated to advancing responsible
          culture throughout its business and communities worldwide, including
          achieving sustainability through net zero carbon emissions by 2030,
          and actively using its platform in the art world to amplify
          under-represented voices and support positive change.
        </p>
      </div>
      <Footer/>
    </div>
  )
}

export default Models
