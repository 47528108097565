import React from 'react'
import { Col, Row } from 'reactstrap'
const User = () => {
  return (
    <div>
      <div className='mt-5'>
        <Row>
          <Col className='' sm='12' md='12'>
            <table className=' w-100'>
              <tr className='table-head mb-3'>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Gender</th>
              </tr>

              <tr>
                <td>John Doe</td>
                <td>JohnDoe@gmail.com</td>
                <td>08138030509</td>
                <td>Male</td>
              </tr>

              <tr>
                <td>John Doe</td>
                <td>JohnDoe@gmail.com</td>
                <td>08138030509</td>
                <td>Male</td>
              </tr>

              <tr>
                <td>John Doe</td>
                <td>JohnDoe@gmail.com</td>
                <td>08138030509</td>
                <td>Male</td>
              </tr>

              <tr>
                <td>John Doe</td>
                <td>JohnDoe@gmail.com</td>
                <td>08138030509</td>
                <td>Male</td>
              </tr>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default User
