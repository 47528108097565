import React from 'react'
import {  Outlet } from 'react-router-dom'
import AppLayout from '../components/AdminLayout'
import { connect } from 'react-redux'
import decode from 'jwt-decode'


const PrivateRoute = ({authorized}) => {
  if (authorized) {
    const decodedUser = decode(authorized)

    if (decodedUser.exp * 1000 > Date.now()) {
      return (
        <AppLayout authorized={authorized} currentUser={decodedUser.data}>
          <Outlet />
        </AppLayout>
      )
    } else {
      window.location.href = '/auth/login'
    }
  } else {
    window.location.href = '/auth/login'
  }
}

const mapStateToProps = ({ auth }) => {
  const { authorized, message, loading } = auth
  return { authorized, message, loading }
}
export default connect(mapStateToProps, null)(PrivateRoute)
