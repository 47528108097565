import { combineReducers } from 'redux'
import auth from './Auth/reducer'
import nav from './Nav/reducer'
import product from './Product/reducer'
import bid from './Bid/reducer'
import transaction from './Transaction/reducer'


const Reducers = combineReducers({
  auth,
  nav,
  product,
  bid,
  transaction,
})

export default Reducers
