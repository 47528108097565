import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'reactstrap'
import CountDown from '../../components/Countdown'
import p1 from '../../assets/img/ppv1.MP4'
import Swal from 'sweetalert2'
import { getSingleProduct, bidProduct } from '../../redux/actions'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import decode from 'jwt-decode'
import Footer from '../../components/UserLayout/Footer'

const ProductDetails = ({ singleProduct, getSingleProduct, bidProduct }) => {
  const { id } = useParams()

  const [bidPrice, setBidPrice] = useState()
  const increment = () => {
    setBidPrice(bidPrice * 1 + 500)
  }
  const decrement = () => {
    setBidPrice(bidPrice * 1 - 500)
  }

  useEffect(
    () => {
      getSingleProduct(id)
    },
    // eslint-disable-next-line
    []
  )
  useEffect(() => {
    if (singleProduct) {
      setBidPrice(singleProduct.startingBidPrice)
    }
  }, [singleProduct])

  const handleBid = () => {
    let authorized = false
    const token = localStorage.getItem('pristineToken')
    if (token) {
      const decodedUser = decode(token)
      const isValid = decodedUser.exp * 1000 > Date.now()
      if (isValid) {
        authorized = true
      } else {
        authorized = false
      }
    } else {
      authorized = true
    }
    if (!authorized) {
      Swal.fire({
        title: 'Unauthorized',
        text: 'You have to login to place a bid',
        icon: 'error',
        showConfirmButton: true
      })
    }
    if (bidPrice < singleProduct.startingBidPrice) {
      Swal.fire({
        title: 'Error',
        text: 'Your bid price is less than the auction price',
        icon: 'error',
        showConfirmButton: true
      })
    } else {
      bidProduct(id, { biddingPrice: bidPrice })
    }
  }
  return (
    singleProduct && (
      <>
        <div className='px-md-5 p-3 py-5'>
          <Row>
            <Col sm='12' md='6'>
              {singleProduct.file.slice(
                (Math.max(0, singleProduct.file.lastIndexOf('.')) || Infinity) +
                  1
              ) === ('jpg' || 'jpeg' || 'png') ? (
                <img
                  src={singleProduct.file}
                  width='100%'
                  height='auto'
                  alt='...'
                />
              ) : (
                <video
                  height='500'
                  className='w-100 p-0 bg-dark'
                  controls
                  autoPlay
                  muted
                >
                  <source src={singleProduct.file} />
                  Your browser does not support the video tag
                </video>
              )}
            </Col>
            <Col sm='12' md='6'>
              <h3
                className=''
                style={{
                  color: '#68402eea'
                }}
              >
                {singleProduct.name}
              </h3>

              <p>{singleProduct.description}</p>

              <p>
                Starting bid:{' '}
                <span className='fw-bold'>{`$ ${singleProduct.startingBidPrice}`}</span>{' '}
              </p>

              <div className='product-details-countdown mb-3 py-2 px-2 w-100'>
                <CountDown date={new Date(singleProduct.dueDate).getTime()} />
              </div>

              <p>
                Auction ends{' '}
                <span className='fw-bold'>
                  {' '}
                  {new Date(singleProduct.dueDate).toUTCString()}
                </span>
              </p>

              <div className='d-flex align-items-center'>
                <div
                  className='d-flex '
                  style={{
                    marginRight: '13px'
                  }}
                >
                  <h6
                    className=' py-2 px-3 rounded-left mb-0 btn fw-bold'
                    style={{
                      background: '#68402eea',
                      color: 'white'
                    }}
                    onClick={decrement}
                  >
                    -
                  </h6>

                  <input
                    type='number'
                    defaultValue={bidPrice}
                    onChange={e => {
                      setBidPrice(e.target.value)
                    }}
                    className='px-2 py-2 border-none bid-input'
                    style={{
                      border: 'none',
                      width: '100px',
                      outline: 'none'
                    }}
                  />
                  <h6
                    className=' py-2 px-3 rounded-right mb-0 btn  fw-bold'
                    style={{
                      background: '#68402eea',
                      color: 'white'
                    }}
                    onClick={increment}
                  >
                    +
                  </h6>
                </div>
                <div>
                  <Button
                    className=' ml-3 px-4 py-2'
                    onClick={handleBid}
                    style={{
                      background: '#68402eea'
                    }}
                  >
                    Bid
                  </Button>
                </div>
              </div>

              <div></div>
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    )
  )
}

const mapStateToProps = ({ product }) => {
  const { singleProduct, loading, deleting } = product
  return { singleProduct, loading, deleting }
}
export default connect(mapStateToProps, { getSingleProduct, bidProduct })(
  ProductDetails
)
