import React from 'react'
import { Row, Col, Card, CardBody, CardImg } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import CountDown from '../../components/Countdown'

const Trending = ({ products }) => {
  console.log(products)
  return (
    <div
      className=' px-md-5 px-4 py-3'
      style={{
        background: 'rgba(250,250,248,0.5)'
      }}
    >
      <div className='mb-4'>
        <h3>Trending</h3>
        <p>See what's popular across thousands of items.</p>
      </div>

      <Row>
        {products.map(el => (
          <Col sm='12' md='4' className='mb-4 '>
            <NavLink to={`/product-details/${el._id}`}>
              <Card className='overflow-hidden h-100'>
                <div className='zoom-container'>
                  <CardImg
                    className='zoom w-100'
                    src={el.file}
                    alt='...'
                    height='300px'
                  />
                  <div className='product-countdown py-3 px-2 ml-5 w-100'>
                    <CountDown date={new Date(el.dueDate).getTime()} />
                  </div>
                </div>
                <CardBody>
                  <div className='product-description pt-1 px-2 ml-5 w-100'>
                    <p className='mb-1'>{el.category}</p>
                    <h3 style={{
                      fontSize:'20px'
                    }}>{el.name}</h3>
                    <p>{`Starting Bid : $${el.startingBidPrice}`}</p>
                  </div>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Trending
