import React, { useState } from 'react'
import { Row, Form, Col, Button } from 'reactstrap'
// import logo from '../../assets/img/TradeMaxi.png'
import { ThreeDots } from 'react-loader-spinner'
import { TiEye } from 'react-icons/ti'
import { IoMdEyeOff } from 'react-icons/io'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { setAuthState, loginUser } from '../../redux/actions'
// import { NavLink } from 'react-router-dom'

const Signin = ({
  // setAuthState,
  loginUser,
  loading,
  loginError,
  message
}) => {
  const [inputType, setInputType] = useState('password')
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()
  const signinUser = values => {
    // console.log(values)
    loginUser(values)
  }

  return (
    <div className='auth'>
     
      <Row className='gx-0'>
        <Col lg='12' sm='12' className=''>
          <div className='signin-right mx-auto'>
            <div className='mx-auto  signin-form-wrapper card py-4'>
             
              <div className='px-5 py-2 signin-form-div mb-1'>
                <div className='mb-3'>
                  <h3
                    className='text-center mb-2 fontInter'
                    style={{
                      fontWeight: '600',
                      fontSize: '35px'
                    }}
                  >
                    Welcome Back
                  </h3>
                  <p className='text-center'>Log in into your Account</p>
                </div>

                <Form onSubmit={handleSubmit(signinUser)}>
                  <label>Email</label>
                  <input
                    type='email'
                    name='email'
                    className={`w-100 rounded p-2 outline-none ${
                      errors.email ? 'border-danger' : ''
                    }`}
                    style={{
                      marginBottom: '20px'
                    }}
                    {...register('email', {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email'
                      }
                    })}
                  />
                  <div>
                    {errors.email && (
                      <span className='text-danger text-left'>
                        Enter a valid Email
                      </span>
                    )}
                  </div>

                  <div className='password-container'>
                    <div className='password-icon'>
                      {inputType === 'password' ? (
                        <TiEye
                          color='#B1B1B1'
                          size='30px'
                          onClick={() => {
                            setInputType('text')
                          }}
                        />
                      ) : (
                        <IoMdEyeOff
                          color='#B1B1B1'
                          size='30px'
                          onClick={() => {
                            setInputType('password')
                          }}
                        />
                      )}
                    </div>
                    <label>Password</label>
                    <input
                      className={`w-100 p-2 rounded outline-none ${
                        errors.password ? 'border-danger' : ''
                      }`}
                      type={inputType}
                      name='password'
                      {...register('password', {
                        required: true
                      })}
                    />
                    {errors.password && (
                      <span className='text-danger text-left'>
                        Enter your password
                      </span>
                    )}
                  </div>

                  <div className='d-flex d-none justify-content-between align-items-center flex-wrap '>
                    <p> </p>
                    <div>
                      <p className='forgot-password mb-0'>
                        {/* <span> */}
                        <a
                          className='forgot-password-link'
                          href='/auth/forgot-password'
                          style={{
                            textDecoration: 'none',
                            border: 'none',
                            color: '#1736B3',
                            fontSize: '16px',
                            marginLeft: '5px',
                            marginTop: '3px'
                          }}
                        >
                          Forgot Password?
                        </a>
                        {/* </span> */}
                      </p>
                    </div>
                  </div>
                  {/* <NavLink to='/' onClick={loginUser}>
                    <div
                      className=''
                      style={{
                        marginTop: 'px'
                      }}
                    > */}
                  <Button
                    className='py-2 px-3  submit-btn align-items-center mt-3'
                    type='submit'
                    disabled={loading}
                    style={{
                      border: 'none',
                      backgroundColor: '#68402eea'
                    }}
                  >
                    {loading ? (
                      <div className='text-center w-100 text-center'>
                        <ThreeDots
                          color='white'
                          height={'12px'}
                          wrapperStyle={{ display: 'block' }}
                        />
                      </div>
                    ) : (
                      'Log in'
                    )}
                  </Button>
                  {/* </div> */}
                  {/* </NavLink> */}
                  <div className='d-flex align-items-center justify-content-center py-'>
                    <p className='mb-0'>No account?</p>
                    <a
                      className='forgot-password-link fw-bold'
                      href='/auth/register'
                      style={{
                        textDecoration: 'none',
                        border: 'none',
                        color: '#68402ea !important',
                        fontSize: '16px',
                        marginLeft: '5px'
                      }}
                    >
                      Register
                    </a>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { loginError, message, loading } = auth
  return { loginError, message, loading }
}
export default connect(mapStateToProps, { setAuthState, loginUser })(Signin)
