import axios from 'axios'

const BASE_URL = 'https://pristine-v1.vercel.app/'
// const BASE_URL = 'http://localhost:5000/'

const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})
const token = localStorage.getItem('pristineToken')
Axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
// Axios.defaults.headers.common = { Authorization: `bearer ${token}` }

export default Axios
