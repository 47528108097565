import {
  MAKE_PAYMENT,
  MAKE_PAYMENT_FAILED,
  MAKE_PAYMENT_SUCCESS,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_FAILED,
  GET_ALL_TRANSACTIONS_SUCCESS,
  VERIFY_TRANSACTION,
  VERIFY_TRANSACTION_FAILED,
  VERIFY_TRANSACTION_SUCCESS,
  GET_ANALYTICS,
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_SUCCESS
} from '../actions'

export const makePayment = (id, url) => ({
  type: MAKE_PAYMENT,
  payload: { id, url }
})

export const makePaymentSuccess = link => ({
  type: MAKE_PAYMENT_SUCCESS,
  payload: { link }
})

export const makePaymentFailed = error => ({
  type: MAKE_PAYMENT_FAILED,
  payload: { error }
})

export const getAllTransactions = () => ({
  type: GET_ALL_TRANSACTIONS
})

export const getAllTransactionsSuccess = data => ({
  type: GET_ALL_TRANSACTIONS_SUCCESS,
  payload: { data }
})

export const getAllTransactionsFailed = error => ({
  type: GET_ALL_TRANSACTIONS_FAILED,
  payload: { error }
})

export const verifyTransaction = (id, transactionId) => ({
  type: VERIFY_TRANSACTION,
  payload: { id, transactionId }
})

export const verifyTransactionSuccess = data => ({
  type: VERIFY_TRANSACTION_SUCCESS,
  payload: { data }
})

export const verifyTransactionFailed = error => ({
  type: VERIFY_TRANSACTION_FAILED,
  payload: { error }
})

export const getAnalytics = () => ({
  type: GET_ANALYTICS
})

export const getAnalyticsSuccess = data => ({
  type: GET_ANALYTICS_SUCCESS,
  payload: { data }
})

export const getAnalyticsFailed = error => ({
  type: GET_ANALYTICS_FAILED,
  payload: { error }
})
