import React, { useState } from 'react'
import { Row, Form, Col, Button } from 'reactstrap'
// import logo from '../../assets/img/TradeMaxi.png'
import { ThreeDots } from 'react-loader-spinner'

import { TiEye } from 'react-icons/ti'
import { IoMdEyeOff } from 'react-icons/io'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { setAuthState, registerUser } from '../../redux/actions'


const Signin = ({
  // setAuthState,
    registerUser,
  loading,
  loginError,
  message
}) => {
  const [inputType, setInputType] = useState('password')
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()
  const signinUser = values => {
    // console.log(values)
    registerUser(values);
    // window.location.href = '/auth/verify'
    // // createNotification()
  }


  return (
    <div className='auth'>
      {/* <div className='text-center '>
        <img src={logo} alt='..'/>
      </div> */}
      <Row className='gx-0'>
        <Col lg='12' sm='12' className=''>
          <div className='register-sec'>
            <div className='mx-auto  signin-form-wrapper card py-3'>
              {/* <div className='signin-logo text-center mt-2'>
                <img src={logo} alt='ontriv-logo' />
              </div> */}
              <div className='px-5 py-2 signin-form-div mb-1'>
                <div className='mb-3'>
                  <h3
                    className='text-center mb-2 fontInter'
                    style={{
                      fontWeight: '600',
                      fontSize: '35px'
                    }}
                  >
                    Create Your Account
                  </h3>
                  <p className='text-center px-5'>
                    Welcome to the best auction site
                  </p>
                </div>

                <Form onSubmit={handleSubmit(signinUser)}>
                  {' '}
                  <label>First Name</label>
                  <input
                    type='text'
                    name='firstName'
                    className={`w-100 py-2 px-2 rounded`}
                    
                    {...register('firstName', {
                      required: true
                    })}
                  />
                  <div>
                    {errors.firstName && (
                      <span className='text-danger text-left'>
                        Enter your first name
                      </span>
                    )}
                  </div>
                  <label className='mt-1'>Last Name</label>
                  <input
                    type='text'
                    name='lastName'
                    className={`w-100 py-2 px-2 rounded`}
                    
                    {...register('lastName', {
                      required: true
                    })}
                  />
                  <div>
                    {errors.lastName && (
                      <span className='text-danger text-left'>
                        Enter your lastName
                      </span>
                    )}
                  </div>
                  <label className='mt-1'>Email</label>
                  <input
                    type='email'
                    name='email'
                    className={`w-100 py-2 px-2 rounded`}
                    
                    {...register('email', {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email'
                      }
                    })}
                  />
                  <div>
                    {errors.email && (
                      <span className='text-danger text-left'>
                        Enter a valid email
                      </span>
                    )}
                  </div>
                  <div className='password-container'>
                    <div className='password-icon'>
                      {inputType === 'password' ? (
                        <TiEye
                          color='#B1B1B1'
                          size='30px'
                          onClick={() => {
                            setInputType('text')
                          }}
                        />
                      ) : (
                        <IoMdEyeOff
                          color='#B1B1B1'
                          size='30px'
                          onClick={() => {
                            setInputType('password')
                          }}
                        />
                      )}
                    </div>

                    <label className='mt-1'>Password</label>
                    <input
                      className={`w-100 py-2 px-2 rounded`}
                      type={inputType}
                      name='password'
                      {...register('password', {
                        required: true
                      })}
                    />
                    <div>
                      {errors.password && (
                        <span className='text-danger text-left'>
                          Enter a password
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className='my-3'
                    style={{
                      marginTop: 'px'
                    }}
                  >
                    <Button
                      className='py-2  submit-btn align-items-center'
                      type='submit'
                      disabled={loading}
                      style={{
                        // width: '35%',
                        border: 'none',
                        backgroundColor: '#68402eea'
                      }}
                    >
                      {loading ? (
                        <div className='text-center w-100 text-center'>
                          <ThreeDots
                            color='white'
                            height={'12px'}
                            wrapperStyle={{ display: 'block' }}
                          />
                        </div>
                      ) : (
                        'Create Account'
                      )}
                    </Button>
                  </div>
                </Form>

                <div className='d-flex align-items-center justify-content-center py-'>
                  <p className='mb-0'>Already have an account?</p>
                  <a
                    className='forgot-password-link'
                    href='/auth/login'
                    style={{
                      textDecoration: 'none',
                      border: 'none',
                      color: '#68402eea !important',
                      fontSize: '16px',
                      marginLeft: '5px'
                    }}
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { loginError, message, loading } = auth
  return { loginError, message, loading }
}
export default connect(mapStateToProps, { setAuthState, registerUser })(Signin)
