import { all, fork, put, takeEvery } from 'redux-saga/effects'
// import axios from 'axios'
// import { setCurrentUser } from '../../utils/helper';
import { Axios, setCurrentUser } from '../../utils/helper'
import Swal from 'sweetalert2'

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordError
} from '../actions'

function * register ({ payload }) {
  const { data } = payload
  yield console.log(data)

  try {
    const response = yield Axios.post(`/auth/signup`, data)
    console.log(response)
    if (response.status === 201) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      window.location.href = `/auth/login`
      yield put(registerUserSuccess('success'))
    } else {
      Swal.fire({
        title: 'Error',
        text: response.data.message,
        icon: 'error',
        showConfirmButton: true
      })
    }
  } catch (error) {
    console.log(error)
    console.log(error.response)
    yield put(registerUserError('error'))
    let message
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = 'Not found'
          break
        case 401:
          message = 'Invalid credentials'
          break
        case 400:
          message = error.response.message
          break
        default:
          message = error.response.statusText
      }
      registerUserError('error')

      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: true
      })
    }
  }
}

function * login (payload) {
  //   yield put(loginUserSuccess('success'))

  yield console.log(payload.payload)
  try {
    const response = yield Axios.post(
      '/auth/signin',
      payload.payload.userDetails
    )
    console.log(response)
    if (response.status === 200) {
      setCurrentUser(response.data.data, response.data?.token)
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      yield put(loginUserSuccess('success'))
      const { role } = response.data.data
      if (role === 'customer') {
        window.location.href = '/'
      } else {
        window.location.href = '/admin/dashboard'
      }
    }
  } catch (error) {
    let message
    console.log(error);
    console.log(error.message);

    if (error.response) {
      
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Login Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Login Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(loginUserError('Error'))
  }
}

function * forgotPassword ({ payload }) {
  yield console.log(payload.data)
  const { new_password1, new_password2, uid, token } = payload.data

  try {
    const response = yield Axios.post(
      `/accounts/api/v1/password-reset/confirm/${uid}/${token}/`,
      {
        new_password1,
        new_password2
      }
    )
    console.log(response)
    // if (response.data.success) {
    //     yield put(forgotPasswordSuccess(response.data.message));
    //     window.location.href = '/auth/login';

    // } else {
    //     yield put(forgotPasswordError(response.data.message));
    // }
  } catch (error) {
    console.log(error)
    console.log(error.response)
    let message
    if (error.response) {
      message = error.response.data.message
    } else if (error.message) {
      message = error.message
    }
    console.log(message)
    yield put(forgotPasswordError(message))
  }
}

function * logout ({ payload }) {
  // const { history } = payload;
  yield setCurrentUser()
  window.location.href = '/auth/login'
}

export function * watchRegisterUser () {
  yield takeEvery(REGISTER_USER, register)
}
export function * watchLoginUser () {
  yield takeEvery(LOGIN_USER, login)
}
export function * watchLogoutUser () {
  yield takeEvery(LOGOUT_USER, logout)
}
export function * watchForgotPassword () {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

export default function * rootSaga () {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword)
  ])
}
