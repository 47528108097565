import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import logo from '../../../../assets/img/ppLogoWhite.png'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'

const SidebarHeader = ({ menuShadow, setShowMobileSideBar }) => {
  return (
    <div className='navbar-header '>
      <div className=' d-md-block d-flex pt-1 pb-3 text-center'>
        <Link to='/admin/dashboard' className='text-center '>
          <div className='text-center mx-auto w-50 pt-3' style={{
              width:'80px',
              height:"80px"
          }}>
            <img src={logo} alt='ontriv-logo' className='navbar-logo mb-0' width='100px' />
          </div>
          {/* <img src={logoMobile} alt='ontriv-logo' width='30px'  height="30px" className={'d-lg-none p-0'} /> */}
        </Link>
        <div
          className='mobile-menu  mb-0 mr-0'
          onClick={() => {
            setShowMobileSideBar(false)
          }}
        >
          <HiOutlineMenuAlt2 size='26px' color='#0053f4' />
        </div>
      </div>

      <div
        className={classnames('shadow-bottom', {
          'd-none': menuShadow === false
        })}
      />
    </div>
  )
}

export default SidebarHeader
