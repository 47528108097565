/*NAV*/
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION'

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'

export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOG_USER_IN = 'LOG_USER_IN'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'

export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED'

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS'
export const GET_ALL_PRODUCTS_FAILED = 'GET_ALL_PRODUCTS_FAILED'

export const GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT'
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS'
export const GET_SINGLE_PRODUCT_FAILED = 'GET_SINGLE_PRODUCT_FAILED'

export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED'

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED'

export const BID_PRODUCT = 'BID_PRODUCT'
export const BID_PRODUCT_SUCCESS = 'BID_PRODUCT_SUCCESS'
export const BID_PRODUCT_FAILED = 'BID_PRODUCT_FAILED'

export const GET_ALL_BIDS = 'GET_ALL_BIDS'
export const GET_ALL_BIDS_SUCCESS = 'GET_ALL_BIDS_SUCCESS'
export const GET_ALL_BIDS_FAILED = 'GET_ALL_BIDS_FAILED'

export const GET_USER_BID = 'GET_USER_BID'
export const GET_USER_BID_SUCCESS = 'GET_USER_BID_SUCCESS'
export const GET_USER_BID_FAILED = 'GET_USER_BID_FAILED'

export const DELETE_BID = 'DELETE_BID'
export const DELETE_BID_SUCCESS = 'DELETE_BID_SUCCESS'
export const DELETE_BID_FAILED = 'DELETE_BID_FAILED'

export const APPROVE_BID = 'APPROVE_BID'
export const APPROVE_BID_SUCCESS = 'APPROVE_BID_SUCCESS'
export const APPROVE_BID_FAILED = 'APPROVE_BID_FAILED'

export const DECLINE_BID = 'DECLINE_BID'
export const DECLINE_BID_SUCCESS = 'DECLINE_BID_SUCCESS'
export const DECLINE_BID_FAILED = 'DECLINE_BID_FAILED'

export const MAKE_PAYMENT = 'MAKE_PAYMENT'
export const MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS'
export const MAKE_PAYMENT_FAILED = 'MAKE_PAYMENT_FAILED'

export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS'
export const GET_ALL_TRANSACTIONS_SUCCESS = 'GET_ALL_TRANSACTIONS_SUCCESS'
export const GET_ALL_TRANSACTIONS_FAILED = 'GET_ALL_TRANSACTIONS_FAILED'

export const VERIFY_TRANSACTION = 'VERIFY_TRANSACTION'
export const VERIFY_TRANSACTION_SUCCESS = 'VERIFY_TRANSACTION_SUCCESS'
export const VERIFY_TRANSACTION_FAILED = 'VERIFY_TRANSACTION_FAILED'

export const GET_ANALYTICS = 'GET_ANALYTICS'
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS'
export const GET_ANALYTICS_FAILED = 'GET_ANALYTICS_FAILED'

export * from './Auth/action'
export * from './Nav/action'
export * from './Product/action'
export * from './Bid/action'
export * from './Transaction/action'

