import React from "react";
import Countdown from "react-countdown";

// Random component
const Completionist = () => <span>Auction Ended</span>;

// Renderer callback with condition
const renderer = ({ days,hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {days} : {hours} : {minutes} : {seconds}
      </span>
    );
  }
};

const Count = ({date})=>{
    return <Countdown renderer={renderer} date={date}/>
}


export default Count;

