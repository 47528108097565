import React from 'react'
import { Col, Row } from 'reactstrap'
import {NavLink} from 'react-router-dom'
const User = () => {
  return (
    <div>
      <div className='mt-5'>
        <Row>
          <Col className='' sm='12' md='12'>
            <table className=' w-100'>
              <tr className='table-head mb-3'>
                <th>Name</th>
                <th>Email</th>
                <th>Transactions</th>
                <th>Bids</th>
                <th>Action</th>
              </tr>

              <tr>
                <td>John Doe</td>
                <td>JohnDoe@gmail.com</td>
                <td className=''>3</td>
                <td>7</td>
                <td>
                   <NavLink
                      to='/admin/user/1'
                    className='btn user-outline-btn py-1 rounded'
                  >
                    View
                  </NavLink>
                </td>
                
              </tr>

              <tr>
                <td>John Doe</td>
                <td>JohnDoe@gmail.com</td>
                <td className=''>3</td>
                <td>7</td>
                <td>
                  <NavLink
                      to='/admin/user/1'
                    className='btn user-outline-btn py-1 rounded'
                  >
                    View
                  </NavLink>
                </td>
                
              </tr>

              <tr>
                <td>John Doe</td>
                <td>JohnDoe@gmail.com</td>
                <td className=''>3</td>
                <td>7</td>
                <td>
                 <NavLink
                      to='/admin/user/1'
                    className='btn user-outline-btn py-1 rounded'
                  >
                    View
                  </NavLink>
                </td>
                
              </tr>

              <tr>
                <td>John Doe</td>
                <td>JohnDoe@gmail.com</td>
                <td className=''>3</td>
                <td>7</td>
                <td>
                  <NavLink
                      to='/admin/user/1'
                    className='btn user-outline-btn py-1 rounded'
                  >
                    View
                  </NavLink>
                </td>
                
              </tr>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default User
