import React from 'react'
import { NavLink } from 'react-router-dom'
import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai'
import { ImLinkedin } from 'react-icons/im'
import { MdEmail, MdLocationOn } from 'react-icons/md'
import { SiMinutemailer } from 'react-icons/si'
import { HiLink } from 'react-icons/hi'
import logo from '../../assets/img/ppLogo.JPG'

const Footer = () => {
  return (
    <footer className=' bg-black md:pb-3 pt-8 px-7 md:px-20 w-full p-4 px-md-5 px-4 '>
      <div className='d-block d-md-flex justify-content-between '>
        <div className=''>
          {/* <h1 className="text-5xl text-slate-300 mb-1 self-start">PWH</h1> */}

          <img
            src={logo}
            alt='...'
            width='50%'
            className='ml-[-20px] shadow-md'
          />

          {/* <p className='text-white w-md-23 w-100'>
            Leading art and luxury business. Renowned and trusted for its
            expert live and online auctions, as well as its bespoke private
            sales, PRISTINE PIECES offers a full portfolio of global services to
            its clients, including art appraisal, art financing, international
            real estate and education
          </p> */}
          <div className='d-flex flex-row align-items-center my-3'>
            <a href='https://www.instagram.com/pristinepieces_/?igshid=YmMyMTA2M2Y%3D'>
              <AiFillInstagram
                className='text-lg ml-2 mr-3'
                size={'2em'}
                color='white'
              />
            </a>
            <AiFillTwitterCircle
              className='text-lg'
              size={'1.9em'}
              color='white'
            />

            <ImLinkedin className='text-lg ' size={'1.5em'} color='white' />
          </div>
        </div>
        <div className='md:mt-5 mb-2'>
          <div className='d-flex flex-row align-gap-3 mb-3 align-items-center'>
            <MdEmail
              className='text-white text-xl mb-0 '
              fontSize='25px'
              style={{ marginRight: '16px' }}
            />
            <a
              className=' text-white  mb-2'
              href='mailto:info@pristinepiecesauctions.com'
            >
              info@pristinepiecesauctions.com
            </a>
          </div>
          <div className='d-flex flex-row align-gap-3 mb-3 align-items-center'>
            <SiMinutemailer
              className='text-white text-xl mb-0 '
              fontSize='25px'
              style={{ marginRight: '16px' }}
            />
            <p className=' text-white mb-1'>+(515) 599-0234</p>
          </div>
          <div className='d-flex flex-row align-gap-3 mb-3 align-items-center'>
            <HiLink
              className='text-white text-xl mb-0 '
              fontSize='25px'
              style={{ marginRight: '16px' }}
            />
            <p className=' text-white mb-1'>pristinepiecesauctions.com</p>
          </div>
          <div className='d-flex flex-row align-gap-3 mb-3 '>
            <MdLocationOn
              className='text-white text-xl mb-0 '
              fontSize='30px'
              style={{ marginRight: '16px' }}
            />
            <p className=' text-white mb-1'>
              New York Office 777 United Nations Plaza, 7th Floor New York, NY
              10017
            </p>
          </div>
        </div>
        <div className=' text-white'>
          <div className='mb-2'>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1.1em'
              }}
              className={isActive =>
                isActive ? ' text-white fw-bold' : 'mb-3'
              }
              to='/'
            >
              Home
            </NavLink>
          </div>
          <div className='mb-2'>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1.1em'
              }}
              to='/exhibitions'
              className={isActive => (isActive ? ' text-white ' : ' mb-3')}
            >
              Exhibitions
            </NavLink>
          </div>
          <div className='mb-2'>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1.1em'
              }}
              to='/models'
              className={isActive => (isActive ? ' text-white' : 'mb-3')}
            >
              Models
            </NavLink>
          </div>

          <div className='mb-2'>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1.1em'
              }}
              to='/about'
              className={isActive => (isActive ? ' text-white' : 'mb-3')}
            >
              About us
            </NavLink>
          </div>

          <div className='mb-2'>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1.1em'
              }}
              to='/contact'
              className={isActive => (isActive ? ' text-white' : 'mb-3')}
            >
              Contact
            </NavLink>
          </div>
        </div>
      </div>
      {/* <p className="text-slate-400 text-center md:text-left">
        Copyright © FemiDEV
      </p> */}
    </footer>
  )
}

export default Footer
