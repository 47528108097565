import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { FaAward } from 'react-icons/fa'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { IoMdSettings } from 'react-icons/io'
import { RiLogoutBoxRFill } from 'react-icons/ri'
import notificationIcon from '../../../../assets/img/notifications.png'
import userProfilePic from '../../../../assets/img/userProfile.png'
// import notificationIcon from '../../../../assets/img/icon.png'
// import chat from '../../../../assets/img/chat.svg'
import { TopRightModal } from '../../../Modal'
import ClientLog from '../../../../assets/img/clientlogo.png'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
// import { Link } from 'react-router-dom'

export const TopNav = ({ currentSection, setShowMobileSideBar }) => {
  const [userProfileState, updateUserProfileState] = useState(false)
  const [notificationState, updateNotificationState] = useState(false)
  // const [mobileSearch, setMobileSearch] = useState(false)

  return (
    <React.Fragment>
      <div className='navbar-static-top topbar'>
        <div className='navbar-wrapper'>
          <div className='navbar-container'>
            <div className='navbar-collapse d-flex justify-content-between align-items-center'>
              <div className='topbar-left-content mr-auto d-flex align-items-center'>
                <div
                  className='mobile-menu mr-4'
                  onClick={() => {
                    setShowMobileSideBar(true)
                  }}
                >
                  <HiOutlineMenuAlt2 size='26px' />
                </div>
                <h2
                  className='mb-0 active-menu font-weight-bold text-capitalize'
                  style={{
                    color: 'black'
                  }}
                >
                  {currentSection}
                </h2>
              </div>

              <div className='d-flex align-items-center'>
                {/* <div className='mx-3'>
                  <img src={notificationIcon} alt='...' />
                </div> */}

                <div className='mx-2'>
                  <img src={userProfilePic} alt='...' />
                </div>

                <p className='mb-0'>Admin</p>
              </div>

              {/* <TopBar/> */}
            </div>
          </div>
        </div>
      </div>

      <TopRightModal
        modalState={userProfileState}
        setModalState={updateUserProfileState}
      >
        <div className='user-menu-modal'>
          <div
            className='user-menu-img mb-3 d-flex justify-content-between align-items-center'
            onClick={() => updateUserProfileState(!userProfileState)}
          >
            <p> </p>
            <img src={userProfilePic} alt='user-dp' />
          </div>
          <div className='user-menu'>
            <div className='user-menu-header'>
              <h1 className='user-name mb-0'>James John</h1>
              <h6 className='user-email mb-0'>james@gmail.com</h6>
            </div>
            <div className='user-menu-items'>
              <div className='d-flex user-menu-item align-items-center'>
                <AiFillQuestionCircle size='20px' className='user-menu-icon' />
                <h5 className='pl-3 user-menu-text mb-0'>Help</h5>
              </div>

              <div className='d-flex user-menu-item align-items-center'>
                <FaAward size='18px' className='user-menu-icon' />
                <h5 className='pl-3 user-menu-text mb-0'>Subscription</h5>
              </div>

              <div className='d-flex user-menu-item align-items-center'>
                <IoMdSettings size='20px' className='user-menu-icon' />
                <h5 className='pl-3 user-menu-text mb-0'>Settings</h5>
              </div>

              <div className='d-flex user-menu-item align-items-center'>
                <RiLogoutBoxRFill size='20px' className='user-menu-icon' />
                <h5 className='pl-3 user-menu-text mb-0'>Logout</h5>
              </div>
            </div>
          </div>
        </div>
      </TopRightModal>

      <TopRightModal
        modalState={notificationState}
        setModalState={updateNotificationState}
      >
        <div className='notification-modal'>
          <div
            className='notification-img mb-3 d-flex justify-content-between align-items-center'
            onClick={() => updateUserProfileState(!userProfileState)}
          >
            <p> </p>
            <img
              src={notificationIcon}
              alt='user-dp'
              className=' notification-icon'
            />
          </div>
          <div className='notifications-wrapper'>
            <div className='notification-header'>
              <h1 className='notification-header-text mb-0'>NOTIFICATIONS</h1>
              <hr />
            </div>
            <div className='notification-items '>
              <div className='notification-item unread'>
                <h1 className='notification-title'>SUCCESS RATE</h1>
                <h6 className='notification-description'>
                  Photo must be 500px by 500px and nothing more than 180kb
                  nothing more than 180kb
                </h6>
              </div>
              <div className='notification-item'>
                <h1 className='notification-title'>SUCCESS RATE</h1>
                <h6 className='notification-description'>
                  Photo must be 500px by 500px and nothing more than 180kb
                  nothing more than 180kb
                </h6>
              </div>
              <div className='notification-item'>
                <h1 className='notification-title'>SUCCESS RATE</h1>
                <h6 className='notification-description'>
                  Photo must be 500px by 500px and nothing more than 180kb
                  nothing more than 180kb
                </h6>
              </div>
              <div className='notification-item'>
                <h1 className='notification-title'>SUCCESS RATE</h1>
                <h6 className='notification-description'>
                  Photo must be 500px by 500px and nothing more than 180kb
                  nothing more than 180kb
                </h6>
              </div>
              <div className='w-50 mt-1 text-center mx-auto notification-button-wrapper'>
                <Button className='notification-button'>View All</Button>
              </div>
            </div>
          </div>
        </div>
      </TopRightModal>
    </React.Fragment>
  )
}

export const ClientTopNav = () => {
  return (
    <React.Fragment>
      <div className='navbar-static-top topbar'>
        <div className='navbar-wrapper'>
          <div className='navbar-container'>
            <div className='navbar-collapse d-flex justify-content-between align-items-center flex-wrap'>
              <div className='topbar-left-content mr-auto'>
                <img src={ClientLog} alt='navbrand' />
              </div>
              <div className='topbar-right-content '>
                <Button className=''>Logout</Button>
              </div>
              {/* <TopBar/> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
