import React from 'react'
import { Row, Col } from 'reactstrap'
import earrings from '../../assets/img/earrrings.jpeg'
import pp12 from '../../assets/img/pp11.JPG'
import pp14 from '../../assets/img/pp14.JPG'
import pp15 from '../../assets/img/pp15.JPG'
import pp1 from '../../assets/img/pp1.JPG'


import chains from '../../assets/img/chains.jpeg'
import trinkets from '../../assets/img/pp7.JPG'
import rings from '../../assets/img/d.jpg'
import watch from '../../assets/img/w3.jpeg'



import Select from 'react-select'

const categories = [
  {
    label: 'Wrist watches',
    value: 'Wrist watches'
  },
  {
    label: 'Chains',
    value: 'Chains'
  },
  {
    label: 'Trinkets',
    value: 'Trinkets'
  },
  {
    label: 'Rings',
    value: 'Rings'
  },
  {
    label: 'Earrings',
    value: 'Earrings'
  }
]

const Categories = () => {
  return (
    <div className='categories-section px-md-5 px-4 py-4'>
      <h3 className='mb-4'>Explore Popular Categories</h3>

      <div className='d-block d-md-none'>
        <Select options={categories} className=' rounded' />
      </div>

      <div className='mt-2 d-none d-md-block'>
        <Row className=''>
          <Col sm='12' md='3' lg='2'>
            <div
           
            >
              <img src={watch} className='rounded-circle' alt='...' width='170px' style={{
                objectFit: 'cover' ,
                objectPositon:'center'
              }}/>
            </div>
            <p
              className='fw-semibold text-center'
              style={{
                fontSize: '1.2em',
                // width: '170px'

              }}
            >
              Wrist Watches
            </p>
          </Col>

          <Col sm='12' md='3' lg='2'>
            <div>
              <img
                src={earrings}
                className='rounded-circle'
                alt='...'
                width='170px'
              />
            </div>
            <p
              className='fw-semibold text-center'
              style={{
                fontSize: '1.2em'
              }}
            >
              Earrings
            </p>
          </Col>

          <Col sm='12' md='3' lg='2'>
            <img
              src={pp12}
              className='rounded-circle'
              alt='...'
              width='170px'
            />
            <p
              className='fw-semibold text-center'
              style={{
                fontSize: '1.2em'
              }}
            >
              Necklace
            </p>
          </Col>

          <Col sm='12' md='3' lg='2'>
            <img
              src={pp14}
              className='rounded-circle'
              alt='...'
              width='170px'
            />
            <p
              className='fw-semibold text-center'
              style={{
                fontSize: '1.2em'
              }}
            >
              Chains
            </p>
          </Col>

          <Col sm='12' md='3' lg='2'>
          <div className='' >
            <img
              src={trinkets}
              className='rounded-circle'
              alt='...'
              width='170px'
            />
            </div>
            <p
              className='fw-semibold text-center'
              style={{
                fontSize: '1.2em'
              }}
            >
              Trinkets
            </p>
          </Col>

          <Col sm='12' md='3' lg='2'>
            <img
              src={rings}
              className='rounded-circle'
              alt='...'
              width='170px'
            />
            <p
              className='fw-semibold text-center'
              style={{
                fontSize: '1.2em'
              }}
            >
              Rings
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Categories
