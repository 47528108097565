import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
// import Footer from './Footer'
// import MobileNav from './MobileNav'
import Sidebar from './Sidebar'
import {
  HiOutlineShoppingBag,
  // HiOutlineUserCircle,
  HiOutlineMenuAlt3
} from 'react-icons/hi'
import { TiTimes } from 'react-icons/ti'

import ppLogoWhite from '../../assets/img/ppLogoWhite.png'
import { CgProfile } from 'react-icons/cg'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { getUserBid } from '../../redux/actions'
import { connect } from 'react-redux'
import decode from 'jwt-decode'

const Layout = ({
  children,
  authorized,

  getUserBid,
  userBids
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [openSidebar, setOpenSidebar] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const token = localStorage.getItem('pristineToken')

  useEffect(() => {
    if(token){
       const decodedUser = decode(token)
    const isValid = decodedUser.exp * 1000 > Date.now()
    if (isValid) {
          getUserBid()

    } 
    }
    // eslint-disable-next-line
  }, [token])

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar)
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (token) {
      const decodedUser = decode(token).data
      setCurrentUser(decodedUser)
    }
  }, [])

  return (
    <div className='d-flex flex-column '>
      <nav className='navbar shadow d-flex bg-light justify-between bg-white px-md-5 px-4 fixed-top  py-3'>
        <a
          className=' text-success fw-bold overflow-hidden'
          href='/'
          style={{
            textDecoration: 'none',
            fontSize: '1.7em',
            width: '50px',
            height: '50px'
          }}
        >
          <img src={ppLogoWhite} alt='...' width='70px' />
        </a>

        <div className='d-md-flex justify-content-between align-items-center  w-50 d-none '>
          <div>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '1.1em'
              }}
              className={isActive => (isActive ? ' fw-bold' : '')}
              to='/'
            >
              Home
            </NavLink>
          </div>
          <div>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '1.1em'
              }}
              to='/exhibitions'
              className={isActive => (isActive ? ' ' : ' text-black')}
            >
              Exhibitions
            </NavLink>
          </div>
          <div>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '1.1em'
              }}
              to='/models'
              className={isActive => (isActive ? '' : 'text-black')}
            >
              Models
            </NavLink>
          </div>

          <div>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '1.1em'
              }}
              to='/about'
              className={isActive => (isActive ? '' : 'text-black')}
            >
              About us
            </NavLink>
          </div>

          <div>
            <NavLink
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '1.1em'
              }}
              to='/contact'
              className={isActive => (isActive ? '' : 'text-black')}
            >
              Contact
            </NavLink>
          </div>
        </div>
        <div className='d-none d-md-block'>
          {authorized ? (
            <div className='d-flex justify-content-between align-items-center  flex-wrap '>
              <div>
                <NavLink to='/bid' className='mx-auto bid-container'>
                  <HiOutlineShoppingBag
                    fontSize='35px'
                    className='font-weight-light'
                    style={{
                      marginRight: '25px'
                    }}
                  />
                  <p className='bid-count'>{userBids.length}</p>
                </NavLink>
              </div>
              <div>
                <Dropdown isOpen={isOpen} toggle={toggle}>
                  <DropdownToggle
                    className='bg-transparent text-black outline-none border-none'
                    style={{
                      outline: 'none'
                    }}
                  >
                    <CgProfile fontSize='40px' className='border-none' />
                  </DropdownToggle>
                  <DropdownMenu
                    className='py-0 mt-0 rounded'
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: 'auto',
                              maxHeight: 100
                            }
                          }
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      style={{
                        background: '#68402eea',
                        color: 'white'
                      }}
                      disabled
                    >
                      {currentUser.name}
                    </DropdownItem>
                    <DropdownItem>
                      <a href='/auth/login'>Logout</a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <div className='d-flex justify-content-between align-items-center  flex-wrap '>
              <div>
                <NavLink
                  to='/auth/login'
                  className=' btn mx-3 px-3 text-white'
                  style={{
                    background: '#68402eea',
                    color: '#68402eea'
                  }}
                >
                  Login
                </NavLink>
              </div>
              <div>
                <NavLink
                  to='/auth/register'
                  className='btn  px-3'
                  style={{
                    background: 'transparent',
                    border: '1.5px solid #68402eea',
                    color: '#68402eea !important'
                  }}
                >
                  Create Account
                </NavLink>
              </div>
            </div>
          )}
        </div>

        <div className='d-block d-md-none'>
          <div className='d-flex justify-content-between align-items-center  flex-wrap '>
            {authorized && (
              <div>
                <NavLink to='/bid' className='mx-auto bid-container'>
                  <HiOutlineShoppingBag
                    fontSize='25px'
                    className='font-weight-light'
                    style={{
                      marginRight: '10px'
                    }}
                  />
                  <p className='bid-count'>{userBids.length}</p>
                </NavLink>
              </div>
            )}

            {authorized && (
              <div>
                <Dropdown isOpen={isOpen} toggle={toggle}>
                  <DropdownToggle
                    className='bg-transparent text-black outline-none border-none px-1'
                    style={{
                      outline: 'none'
                    }}
                  >
                    <CgProfile fontSize='25px' className='border-none' />
                  </DropdownToggle>
                  <DropdownMenu
                    className='py-0 mt-0 rounded'
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: 'auto',
                              maxHeight: 100
                            }
                          }
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      style={{
                        background: '#68402eea',
                        color: 'white'
                      }}
                      disabled
                    >
                      John Doe
                    </DropdownItem>
                    <DropdownItem>Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}

            <div className='d-block d-md-none'>
              {!openSidebar && (
                <HiOutlineMenuAlt3
                  fontSize='30px'
                  color='#68402eea'
                  onClick={toggleSidebar}
                />
              )}
              {openSidebar && (
                <TiTimes
                  fontSize='40px'
                  color='#68402eea'
                  onClick={toggleSidebar}
                />
              )}
            </div>
          </div>
        </div>
      </nav>
      <div className='user-content'>{children}</div>
      {/* <div className='d-block d-md-none'>
        <MobileNav />
      </div> */}
      {/* <Footer /> */}
      <Sidebar
        openSidebar={openSidebar}
        authorized={authorized}
        toggleSidebar={toggleSidebar}
      />
    </div>
  )
}

const mapStateToProps = ({ bid, transaction }) => {
  const { userBids, loading, deleting } = bid

  return { userBids, loading, deleting }
}
export default connect(mapStateToProps, {
  getUserBid
})(Layout)
