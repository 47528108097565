import {
  BID_PRODUCT,
  BID_PRODUCT_SUCCESS,
  BID_PRODUCT_FAILED,
  GET_ALL_BIDS,
  GET_ALL_BIDS_SUCCESS,
  GET_ALL_BIDS_FAILED,
  GET_USER_BID,
  GET_USER_BID_SUCCESS,
  GET_USER_BID_FAILED,
  DELETE_BID,
  DELETE_BID_SUCCESS,
  DELETE_BID_FAILED,
  APPROVE_BID,
  APPROVE_BID_SUCCESS,
  APPROVE_BID_FAILED,
  DECLINE_BID,
  DECLINE_BID_SUCCESS,
  DECLINE_BID_FAILED
} from '../actions'

const INIT_STATE = {
  loading: false,
  approving: false,
  declining: false,
  deleting: false,
  allBids: [],
  userBids: []
}

const productReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BID_PRODUCT:
      return { ...state, creating: true }
    case BID_PRODUCT_SUCCESS:
      return { ...state, creating: false }
    case BID_PRODUCT_FAILED:
      return { ...state, creating: false }

    case GET_ALL_BIDS:
      return { ...state, loading: true }
    case GET_ALL_BIDS_SUCCESS:
      return { ...state, loading: false, allBids: action.payload.data.data }
    case GET_ALL_BIDS_FAILED:
      return { ...state, loading: false }

    case GET_USER_BID:
      return { ...state, loading: true }
    case GET_USER_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        userBids: action.payload.data.data
      }
    case GET_USER_BID_FAILED:
      return { ...state, loading: false }

    case DELETE_BID:
      return { ...state, deleting: true }
    case DELETE_BID_SUCCESS:
      return { ...state, deleting: false }
    case DELETE_BID_FAILED:
      return { ...state, deleting: false }

    case APPROVE_BID:
      return { ...state, deleting: true }
    case APPROVE_BID_SUCCESS:
      return { ...state, deleting: false }
    case APPROVE_BID_FAILED:
      return { ...state, deleting: false }

    default:
      return { ...state }
  }
}

export default productReducer
