import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import Axios from '../../utils/Axios'
import Swal from 'sweetalert2'

import {
  BID_PRODUCT,
  bidProductSuccess,
  bidProductFailed,
  GET_ALL_BIDS,
  getAllBidsSuccess,
  getAllBidsFailed,
  GET_USER_BID,
  getUserBidFailed,
  getUserBidSuccess,
  DELETE_BID,
  deleteBidSuccess,
  deleteBidFailed,
  APPROVE_BID,
  approveBidSuccess,
  approveBidFailed,
  DECLINE_BID,
  declineBidSuccess,
  declineBidFailed
} from '../actions'

function * getAllBids () {
  try {
    const response = yield Axios.get('/bid')
    console.log(response)
    if (response.status === 200) {
      yield put(getAllBidsSuccess(response.data))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(getAllBidsFailed('error'))
  }
}

function * bidProduct (payload) {
  const { id, price } = payload.payload
  yield console.log(id, price)
  try {
    const response = yield Axios.post(`/bid/${id}`, price)
    console.log(response)
    if (response.status === 201) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      yield put(bidProductSuccess('success'))
      // yield call(getAllBids)
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(bidProductFailed('error'))
  }
}

function * getUserBid () {
  try {
    const response = yield Axios.get(`/bid/mine`)
    console.log(response)
    if (response.status === 200) {
      yield put(getUserBidSuccess(response.data))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(getUserBidFailed('error'))
  }
}

function * deleteBid (payload) {
  yield console.log(payload)
  const { id } = payload.payload
  try {
    const response = yield Axios.delete(`/bid/${id}`)
    console.log(response)
    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      yield put(deleteBidSuccess('success'))
      yield call(getUserBid)
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(deleteBidFailed('error'))
  }
}

function * approveBid (payload) {
  yield console.log(payload)
  const { id } = payload.payload
  console.log(id)
  try {
    const response = yield Axios.put(`/bid/approve/${id}`)
    console.log(response)
    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      yield put(approveBidSuccess('success'))
      yield call(getAllBids)
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(approveBidFailed('error'))
  }
}

function * declineBid (payload) {
  yield console.log(payload)
  const { id } = payload.payload
  try {
    const response = yield Axios.put(`/bid/decline/${id}`)
    console.log(response)
    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      yield put(declineBidSuccess('success'))
      yield call(getAllBids)
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(declineBidFailed('error'))
  }
}

export function * watchBidProduct () {
  yield takeEvery(BID_PRODUCT, bidProduct)
}
export function * watchGetAllBids () {
  yield takeEvery(GET_ALL_BIDS, getAllBids)
}
export function * watchGetUserBid () {
  yield takeEvery(GET_USER_BID, getUserBid)
}
export function * watchApproveBid () {
  yield takeEvery(APPROVE_BID, approveBid)
}
export function * watchDeclineBid () {
  yield takeEvery(DECLINE_BID, declineBid)
}
export function * watchDeleteBid () {
  yield takeEvery(DELETE_BID, deleteBid)
}

export default function * rootSaga () {
  yield all([fork(watchBidProduct)])
  yield all([fork(watchGetAllBids)])
  yield all([fork(watchGetUserBid)])
  // yield all([fork(watchUpdateProduct)])
  yield all([fork(watchDeleteBid)])
  yield all([fork(watchApproveBid)])
  yield all([fork(watchDeclineBid)])
}
