import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILED,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILED,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILED,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED
} from '../actions'

const INIT_STATE = {
  loading: false,
  updating: false,
  creating: false,
  deleting: false,
  allProducts: [],
  singleProduct: null
}

const productReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_PRODUCT:
      return { ...state, creating: true }
    case CREATE_PRODUCT_SUCCESS:
      return { ...state, creating: false }
    case CREATE_PRODUCT_FAILED:
      return { ...state, creating: false }

    case GET_ALL_PRODUCTS:
      return { ...state, loading: true }
    case GET_ALL_PRODUCTS_SUCCESS:
      return { ...state, loading: false, allProducts: action.payload.data.data }
    case GET_ALL_PRODUCTS_FAILED:
      return { ...state, loading: false }

    case GET_SINGLE_PRODUCT:
      return { ...state, loading: true }
    case GET_SINGLE_PRODUCT_SUCCESS:
      return { ...state, loading: false, singleProduct: action.payload.data.data }
    case GET_SINGLE_PRODUCT_FAILED:
      return { ...state, loading: false }

    case UPDATE_PRODUCT:
      return { ...state, creating: true }
    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, creating: false }
    case UPDATE_PRODUCT_FAILED:
      return { ...state, creating: false }

    case DELETE_PRODUCT:
      return { ...state, deleting: true }
    case DELETE_PRODUCT_SUCCESS:
      return { ...state, deleting: false }
    case DELETE_PRODUCT_FAILED:
      return { ...state, deleting: false }

    default:
      return { ...state }
  }
}

export default productReducer
