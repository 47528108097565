import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILED,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILED,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILED,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED
} from '../actions'

export const createProduct = productDetails => ({
  type: CREATE_PRODUCT,
  payload: { productDetails }
})

export const createProductSuccess = message => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload: { message }
})

export const createProductFailed = error => ({
  type: CREATE_PRODUCT_FAILED,
  payload: { error }
})

export const getAllProducts = () => ({
  type: GET_ALL_PRODUCTS
})

export const getAllProductsSuccess = data => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  payload: { data }
})

export const getAllProductsFailed = error => ({
  type: GET_ALL_PRODUCTS_FAILED,
  payload: { error }
})

export const getSingleProduct = id => ({
  type: GET_SINGLE_PRODUCT,
  payload: { id }
})

export const getSingleProductSuccess = data => ({
  type: GET_SINGLE_PRODUCT_SUCCESS,
  payload: { data }
})

export const getSingleProductFailed = error => ({
  type: GET_SINGLE_PRODUCT_FAILED,
  payload: { error }
})

export const updateProduct = (id, details) => ({
  type: UPDATE_PRODUCT,
  payload: { id, details }
})

export const updateProductSuccess = message => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: { message }
})

export const updateProductFailed = error => ({
  type: UPDATE_PRODUCT_FAILED,
  payload: { error }
})

export const deleteProduct = id => ({
  type: DELETE_PRODUCT,
  payload: { id }
})

export const deleteProductSuccess = message => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: { message }
})

export const deleteProductFailed = error => ({
  type: DELETE_PRODUCT_FAILED,
  payload: { error }
})
