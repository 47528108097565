import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ProtectedRoutes from './utils/ProtectedRoute'
import UserRoutes from './utils/UserRoute'

import Signup from './views/Auth/Register'
import Signin from './views/Auth/Login'
import Verify from './views/Auth/Verify'
import ForgotPassword from './views/Auth/ForgotPassword'
import Dashboard from './views/Dashboard'
import Disputes from './views/Dispute'

// import Profile from './views/Profile'
import Transactions from './views/Transactions'

// import Wallet from './views/Wallet'
import Home from './views/Home'
import ProductDetails from './views/ProductDetails'
import Exhibition from './views/Exhibition'
import Models from './views/Models'
import Bid from './views/Bid'
import UserManagement from './views/UserManagement'
import UserDetails from './views/UserManagement/UserDetails'

import Product from './views/Product'
import AdminProductDetails from './views/Product/ProductDetails'
import EditProductDetails from './views/Product/EditProduct'
import VerifyPayment from './views/VerifyPayment'

import Bids from './views/Bids'
import Model from './views/Model'
import About from './views/About'
import Contact from './views/Contact'


const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/admin' element={<ProtectedRoutes />}>
          <Route exact path='/admin/dashboard' element={<Dashboard />} />
          <Route
            exact
            path='/admin/user-management'
            element={<UserManagement />}
          />
          <Route exact path='/admin/products' element={<Product />} />
          {/* <Route exact path='/admin/profile' element={<Profile />} /> */}
          <Route exact path='/admin/disputes' element={<Disputes />} />
          <Route exact path='/admin/transactions' element={<Transactions />} />
          <Route exact path='/admin/bids' element={<Bids />} />
          <Route exact path='/admin/models' element={<Model />} />
          <Route
            exact
            path='/admin/product-details/:id'
            element={<AdminProductDetails />}
          />
          <Route exact path='/admin/user/:id' element={<UserDetails />} />

          <Route
            exact
            path='/admin/edit-details/:id'
            element={<EditProductDetails />}
          />
        </Route>

        <Route path='/' element={<UserRoutes />}>
          <Route exact path='/' element={<Home />} />
          <Route
            exact
            path='/product-details/:id'
            element={<ProductDetails />}
          />
          <Route exact path='/exhibitions' element={<Exhibition />} />
          <Route exact path='/models' element={<Models />} />
          <Route exact path='/bid' element={<Bid />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />


          <Route exact path='/verify-payment' element={<VerifyPayment />} />
        </Route>

        <Route exact path='/auth/register' element={<Signup />} />
        <Route exact path='/auth/login' element={<Signin />} />
        <Route exact path='/auth/verify' element={<Verify />} />
        <Route
          exact
          path='/auth/forgot-password'
          element={<ForgotPassword />}
        />
      </Routes>
    </BrowserRouter>
  )
}
export default RouterConfig
