import React, {  useEffect } from 'react'
import { Row,  Col } from 'reactstrap'
import logo from '../../assets/img/TradeMaxi.png'
import verifyEmail from '../../assets/img/Vector.png'
// import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { setAuthState, loginUser } from '../../redux/actions'
import createNotification from '../../utils/Notification'


const Signin = ({
  // setAuthState,
  //   loginUser,
  loading,
  loginError,
  message
}) => {

  

  useEffect(() => {
    console.log(loginError, message, loading)
    if (loginError.length > 0) {
      createNotification('error', loginError)
    }
    if (message.length > 0) {
      createNotification('info', loginError)
    }
  }, [loginError, message, loading])

  return (
    <div className='auth'>
      <div className='text-center '>
        <img src={logo} alt='...'/>
      </div>
      <Row className='gx-0'>
        <Col lg='12' sm='12' className=''>
          <div className='signin-right'>
            <div className='mx-auto w-50  signin-form-wrapper'>
              {/* <div className='signin-logo text-center my-2 mb-4'>
                <img src={logo} alt='...' />
              </div> */}

              <div className='text-center'>
                <img src={verifyEmail} alt='...' />
              </div>

              <div className='px-5 py-2 signin-form-div mb-1'>
                <div className='mb-3'>
                  <h3
                    className='text-center mb-2 fontInter'
                    style={{
                      fontWeight: '600',
                      fontSize: '35px'
                    }}
                  >
                    Verify Your Email
                  </h3>
                  <p className='text-center'>
                    Please check your email inbox for the verification mail that
                    we sent to you and click on the button to verify your
                    account
                  </p>
                </div>

                <div className='text-center py-3'>
                  <p className='mb-0'>
                    Didn't get a link?
                    <span
                      className='forgot-password-link'
                      href='#'
                      style={{
                        textDecoration: 'none',
                        border: 'none',
                        color: '#1736B3',
                        fontSize: '16px',
                        marginLeft: '5px'
                      }}
                    >
                      Resend
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { loginError, message, loading } = auth
  return { loginError, message, loading }
}
export default connect(mapStateToProps, { setAuthState, loginUser })(Signin)
