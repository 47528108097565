import React from 'react'
import { Row, Col, Card } from 'reactstrap'

import p1 from '../../assets/img/pp26.JPG'
import p2 from '../../assets/img/pp18.JPG'
import p3 from '../../assets/img/pp3.JPG'
import p7 from '../../assets/img/pp7.JPG'
import p4 from '../../assets/img/pp4.JPG'

const Trending = ({ products }) => {
  console.log(products)
  return (
    <div
      className='py-3 px-md-5 px-4'
      style={{
        background: 'white'
      }}
    >
      <div className='mb-4'>
        <h3>Past Auction</h3>
        <p>See what's popular across thousands of items.</p>
      </div>

      <Row>
        {products.map(el => (
          <Col sm='12' md='4' className='mb-4 '>
            <Card className='overflow-hidden zoom-container'>
              <img
                className='zoom w-100'
                src={el.file}
                alt='...'
                height='400px'
              />
              <div className=''>
                <div className='product-countdown py-3 px-2 ml-5 w-100'>
                  Auction ended
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Trending
