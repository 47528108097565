import React, { useEffect, useState } from 'react'
import Categories from './Categories'
import Trending from './Trending'
import Upcoming from './Upcoming'
import Past from './Past'
import { BiSearchAlt2 } from 'react-icons/bi'
import { connect } from 'react-redux'
import { getAllProducts } from '../../redux/actions'
import Footer from '../../components/UserLayout/Footer'

// import Select from 'react-select'

const Home = ({ getAllProducts, allProducts, loading, product }) => {
  useEffect(() => {
    getAllProducts()
    // eslint-disable-next-line
  }, [])
  console.log(allProducts)

  const [searchString, setSearchString] = useState('')

  return (
    allProducts.length > 0 && (
      <>
        <div className='h-100vh hero-section'>
          <div className='hero-cta-section'>
            <h3>JOIN OUR NEXT AUCTION! FIND YOUR JEWELLERIES</h3>
            <div className='rounded d-flex bg-white py-2 px-md-3 px-2'>
              <input
                onChange={e => {
                  setSearchString(e.target.value)
                }}
                className='border-none py-md-3 py-2'
                placeholder='What are you looking for ?'
              />
              <button
                className='bg-black text-white rounded d-none d-md-block '
                onClick={() => {
                  if (searchString.length > 0) {
                    window.location.href = `/exhibitions?search=${searchString}`
                  }
                }}
              >
                Search
              </button>
              <button className='bg-black text-white rounded d-block d-md-none'>
                <BiSearchAlt2 fontSize='24px' />
              </button>
            </div>
            <div className='hero-form-section'></div>
          </div>
        </div>
        <Categories />
        <Trending
          products={allProducts.filter(el => el.auctionType === 'Trending')}
        />
        <Past products={allProducts.filter(el => el.auctionType === 'Past')} />
        <Upcoming
          products={allProducts.filter(el => el.auctionType === 'Upcoming')}
        />
        <Footer />
      </>
    )
  )
}

const mapStateToProps = ({ product }) => {
  const { allProducts, loading } = product
  return { allProducts, loading }
}
export default connect(mapStateToProps, { getAllProducts })(Home)
