import React, { useEffect, useState } from 'react'
import { Card, Button } from 'reactstrap'
import pp1 from '../../assets/img/pp25.JPG'
import pp3 from '../../assets/img/pp3.JPG'
import {
  getUserBid,
  deleteBid,
  makePayment,
  verifyTransaction
} from '../../redux/actions'
import { connect } from 'react-redux'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3'

const Bid = ({
  userBids,
  getUserBid,
  deleteBid,
  deleting,
  public_key,
  makePayment,
  verifyTransaction
}) => {
  useEffect(() => {
    getUserBid()
    // eslint-disable-next-line
  }, [])

  const [productId, setProductId] = useState('')

  const hostname = window.location.host
  console.log(hostname)

  let params = new URL(document.location).searchParams
  let status = params.get('status')
  const tx_ref = params.get('tx_ref')
  const transaction_id = params.get('transaction_id')
  console.log(status, tx_ref, transaction_id)

  useEffect(() => {
    console.log(status, tx_ref, transaction_id)

    if (status && tx_ref && transaction_id) {
      verifyTransaction(tx_ref, transaction_id)
    }
  }, [status, tx_ref, transaction_id])

  useEffect(() => {
    if (public_key) {
      handleFlutterPayment({
        callback: response => {
          console.log(response)
          verifyTransaction(response.tx_ref, response.transaction_id)
          closePaymentModal() // this will close the modal programmatically
        },
        onClose: () => {}
      })
    }
  }, [public_key])

  console.log(public_key)

  const config = {
    public_key,
    tx_ref: productId,
    amount: 100,
    redirect_url: `http://${hostname}/bid`,
    currency: 'USD',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: 'holarfemilekan049@gmail.com',
      name: 'Femi Deniyi'
    },
    customizations: {
      title: 'my Payment Title',
      description: 'Payment for items in cart',
      logo:
        'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg'
    }
  }

  const handleFlutterPayment = useFlutterwave(config)

  return (
    <div className='bid-section px-md-4 px-2'>
      <Card className='py-4 px-3 '>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <h3 className='mb-0'>Your Bids </h3>

          <input
            placeholder='Search items. . .'
            className='outline-none rounded  p-2'
            style={{
              border: '1px solid black'
            }}
          />
        </div>
        <div className='w-100 overflow-scroll'>
          <table className=' w-100 '>
            <tr className='table-head mb-3'>
              <th>Image</th>
              <th>Name</th>
              <th>Category</th>
              <th>Starting Price</th>
              <th>Bid Price</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {userBids.map(el => (
              <tr>
                <td>
                  <div
                    className='overflow-hidden'
                    style={{
                      width: '70px',
                      height: '70px'
                    }}
                  >
                    <img src={el?.product.file} alt='...' width='100px' />
                  </div>
                </td>
                <td>{el?.product.name}</td>
                <td>{el?.product.category}</td>

                <td>{`$${el?.product.startingBidPrice}`}</td>
                <td>{`$${el?.biddingPrice}`}</td>
                <td
                  className={
                    el.status === 'Pending'
                      ? 'text-warning'
                      : el.status === 'Approved'
                      ? 'text-success'
                      : el.status === 'Paid'
                      ? 'text-success'
                      : 'text-danger'
                  }
                >
                  {el.status}
                </td>

                <td
                  className={
                    el.status === 'Approved'
                      ? 'align-items-center  justify-content-between'
                      : ''
                  }
                >
                  {el.status === 'Approved' && (
                    <Button
                      className='btn btn-success'
                      onClick={() => {
                        setProductId(el._id)
                        makePayment(el._id, hostname)
                      }}
                      // onClick={() => {
                      //   handleFlutterPayment({
                      //     callback: response => {
                      //       console.log(response)
                      //       closePaymentModal() // this will close the modal programmatically
                      //     },
                      //     onClose: () => {}
                      //   })
                      // }}
                      style={{
                        marginRight: '10px'
                      }}
                    >
                      Pay
                    </Button>
                  )}
                  {el.status !== 'Paid' && (
                    <Button
                      className='btn transaction-outline-danger'
                      onClick={() => {
                        deleteBid(el._id)
                      }}
                      style={{
                        background: 'transparent',
                        color: '#EE0000',
                        border: 'solid 1px #EE0000'
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </Card>
    </div>
  )
}

const mapStateToProps = ({ bid, transaction }) => {
  const { userBids, loading, deleting } = bid
  const { public_key } = transaction
  return { userBids, loading, deleting, public_key }
}
export default connect(mapStateToProps, {
  getUserBid,
  deleteBid,
  makePayment,
  verifyTransaction
})(Bid)
