import { useState } from 'react'
import { CenteredModal } from '../../components/Modal'
import { Row, Col } from 'reactstrap'
import Select from 'react-select'
import { createProduct } from '../../redux/actions'
import { connect } from 'react-redux'
import { categories, type } from '../../utils/helper'
import { ThreeDots } from 'react-loader-spinner'
import { useForm } from 'react-hook-form'
import Axios from 'axios'

const AddNew = ({ modalState, setModalState, createProduct, loading }) => {
  const [category, setCategory] = useState('')
  const [auctionType, setType] = useState('')
  const [description, setDescription] = useState('')
  const [file, setFile] = useState(null)

  const [uploading, setUploading] = useState('')

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()

  const cloudinaryUpload = async gile => {
    setUploading(true)
    const fd1 = new FormData()
    fd1.append('file', gile)
    fd1.append('upload_preset', 'fadeniyi')
    const response = await Axios.post(
      'https://api.cloudinary.com/v1_1/fadeniyi/image/upload',
      fd1
    )
    setUploading(false)
    return response.data.url
  }

  const handleProductCreation = async values => {
    console.log(values)
    const fileUrl = await cloudinaryUpload(file)

    const productDetails = {
      ...values,
      category,
      auctionType,
      description,
      file: fileUrl
    }
    createProduct(productDetails)
  }

  return (
    <CenteredModal modalState={modalState} setModalState={setModalState}>
      <form onSubmit={handleSubmit(handleProductCreation)}>
        <Row>
          <Col sm='12' md='12' className='mb-2'>
            <label>Name</label>
            <input
              className='w-100 rounded px-2 py-1'
              type='text'
              name='name'
              style={{
                border: 'solid 1px hsl(0, 0%, 80%)'
              }}
              {...register('name', {
                required: true
              })}
            />
            <div>
              {errors.name && (
                <span className='text-danger text-left'>
                  Enter Product Name
                </span>
              )}
            </div>
          </Col>

          <Col sm='12' md='12' className='mb-2'>
            <label>Bid Price</label>
            <input
              className='w-100 rounded px-2 py-1'
              type='number'
              name='startingBidPrice'
              style={{
                border: 'solid 1px hsl(0, 0%, 80%)'
              }}
              {...register('startingBidPrice', {
                required: true
              })}
            />
            <div>
              {errors.startingBidPrice && (
                <span className='text-danger text-left'>
                  Enter starting bid price
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm='12' md='12' className='mb-2'>
            <label>Category</label>
            <Select
              options={categories}
              onChange={e => {
                setCategory(e.value)
              }}
            />
          </Col>
          <Col sm='12' md='12' className='mb-2'>
            <label>Type</label>
            <Select
              options={type}
              onChange={e => {
                setType(e.value)
              }}
            />
          </Col>
          <Col sm='12' md='12' className='mb-2'>
            <label>Due Date</label>
            <input
              type='date'
              name='dueDate'
              style={{
                border: 'solid 1px hsl(0, 0%, 80%)'
              }}
              className='w-100 py-1 rounded px-1 py-2'
              {...register('dueDate', {
                required: true
              })}
            />
            <div>
              {errors.dueDate && (
                <span className='text-danger text-left'>Enter due date</span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm='12' md='12' className=''>
            <label>Description</label>
            <textarea
              className='w-100 px-2 rounded'
              rows='5'
              name='description'
              style={{
                border: 'solid 1px hsl(0, 0%, 80%)'
              }}
              onChange={e => {
                setDescription(e.target.value)
              }}
            />
            <div>
              {errors.description && (
                <span className='text-danger text-left'>Enter description</span>
              )}
            </div>
          </Col>

          <Col sm='12' md='12' className='mb-2'>
            <input
              type='file'
              className='mb-0'
              onChange={e => {
                setFile(e.target.files[0])
              }}
            />
          </Col>

          <Col sm='12' md='12' className='mb-1'>
            <button
              className='w-100 py-2 text-white rounded'
              style={{
                background: '#68402eea'
              }}
              disabled={
                category.length < 0 ||
                auctionType.length < 0 ||
                description.length < 0 ||
                !file
              }
            >
              {loading || uploading ? (
                <div className='text-center w-100 text-center'>
                  <ThreeDots
                    color='white'
                    height={'12px'}
                    wrapperStyle={{ display: 'block' }}
                  />
                </div>
              ) : (
                'Add'
              )}
            </button>
          </Col>
        </Row>
      </form>
    </CenteredModal>
  )
}

const mapStateToProps = ({ product }) => {
  const { creating } = product
  return { creating }
}

export default connect(mapStateToProps, { createProduct })(AddNew)
