import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import Select from 'react-select'
import Footer from '../../components/UserLayout/Footer'
import Axios from '../../utils/Axios'
import Swal from 'sweetalert2'

const Models = () => {
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [age, setAge] = useState('')
  const [gender, setGender] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    console.log(gender.length > 0)
    try {
      setLoading(true)
      if (
        firstName.length > 0 &&
        lastName.length > 0 &&
        phone.length > 0 &&
        email.length > 0 &&
        age.length > 0 &&
        gender.length > 0
      ) {
        const details = {
          firstName,
          lastName,
          phone,
          email,
          age,
          gender
        }
        console.log(details)

        const response = await Axios.post('/models', details)
        console.log(response)
        if (response.status === 201) {
          setLoading(false)
          Swal.fire({
            title: 'Success',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: true
          })
        } else {
          setLoading(true)
        }
      }
    } catch (err) {
      setLoading(false)
      console.log(err.response)
      Swal.fire({
        title: 'Error',
        text: err.message,
        icon: 'error',
        showConfirmButton: true
      })
    }
  }

  return (
    <div className='model-section'>
      <div className='h-100vh hero-section'>
        <div className='hero-cta-section'>
          <h3>Become our top model</h3>
          <div className='hero-form-section'></div>
        </div>
      </div>

      <div className='mt-3 px-md-5 px-3'>
        <h3 className='text-center'>Join Us</h3>

        <form className='d-flex flex-column justify-content-cente model-form my-3 mb-5'>
          <Row className='mb-2'>
            <Col sm='12' md='6'>
              <label>First Name</label>
              <input
                placeholder=''
                className='rounded mb-2 w-100 py-2 px-2'
                onChange={e => {
                  setFirstName(e.target.value)
                }}
              />
            </Col>

            <Col sm='12' md='6'>
              <label>Last Name</label>
              <input
                placeholder=''
                className='rounded mb-2 w-100 py-2 px-2'
                onChange={e => {
                  setLastName(e.target.value)
                }}
              />
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col sm='12' md='6'>
              <label>Phone</label>
              <input
                type='number'
                placeholder=''
                className='rounded mb-2 w-100 py-2 px-2'
                onChange={e => {
                  setPhone(e.target.value)
                }}
              />
            </Col>

            <Col sm='12' md='6'>
              <label>Email</label>
              <input
                placeholder=''
                type='email'
                className='rounded mb-2 w-100 py-2 px-2'
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col sm='12' md='6'>
              <label>Age</label>
              <input
                type='number'
                placeholder=''
                className='rounded mb-2 w-100 py-2 px-2'
                onChange={e => {
                  setAge(e.target.value)
                }}
              />
            </Col>

            <Col sm='12' md='6'>
              <label>Gender</label>
              <Select
                placeholder=''
                className='rounded mb-2 w-100 py-2'
                options={[
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' }
                ]}
                onChange={e => {
                  setGender(e.value)
                }}
              />
            </Col>
          </Row>
          <button
            className=' btn '
            onClick={e => handleSubmit(e)}
            disabled={loading}
          >
            {loading ? 'Join' : 'Join'}
          </button>
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default Models
