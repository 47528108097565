import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import Axios from '../../utils/Axios'
import axios from 'axios';
import Swal from 'sweetalert2'

import {
  CREATE_PRODUCT,
  createProductSuccess,
  createProductFailed,
  GET_ALL_PRODUCTS,
  getAllProductsSuccess,
  getAllProductsFailed,
  GET_SINGLE_PRODUCT,
  getSingleProductFailed,
  getSingleProductSuccess,
  UPDATE_PRODUCT,
  updateProductSuccess,
  updateProductFailed,
  DELETE_PRODUCT,
  deleteProductSuccess,
  deleteProductFailed
} from '../actions'

function * getProducts () {
  try {
    const response = yield axios.get('https://pristine-v1.vercel.app/product')
    console.log(response)
    if (response.status === 200) {
      yield put(getAllProductsSuccess(response.data))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(getAllProductsFailed('error'))
  }
}

function * createProduct (payload) {
  //   yield console.log(payload)
  try {
    const response = yield Axios.post(
      '/product',
      payload.payload.productDetails
    )
    console.log(response)
    if (response.status === 201) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      yield put(createProductSuccess('success'))
      yield call(getProducts)
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(createProductFailed('error'))
  }
}

function * getSingleProduct (payload) {
  try {
    const response = yield axios.get(`https://pristine-v1.vercel.app/product/single/${payload.payload.id}`)
    console.log(response)
    if (response.status === 200) {
      yield put(getSingleProductSuccess(response.data))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(getSingleProductFailed('error'))
  }
}

function * updateProduct (payload) {
  yield console.log(payload)
  const { details, id } = payload.payload
  try {
    const response = yield Axios.put(`/product/${id}`, details)
    console.log(response)
    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      yield put(updateProductSuccess('success'))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(updateProductFailed('error'))
  }
}

function * deleteProduct (payload) {
  yield console.log(payload)
  const { id } = payload.payload
  try {
    const response = yield Axios.delete(`/product/${id}`)
    console.log(response)
    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      yield put(deleteProductSuccess('success'))
      window.location.href = '/admin/products'
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(deleteProductFailed('error'))
  }
}

export function * watchCreateProduct () {
  yield takeEvery(CREATE_PRODUCT, createProduct)
}
export function * watchGetProducts () {
  yield takeEvery(GET_ALL_PRODUCTS, getProducts)
}
export function * watchGetSingleProduct () {
  yield takeEvery(GET_SINGLE_PRODUCT, getSingleProduct)
}
export function * watchUpdateProduct () {
  yield takeEvery(UPDATE_PRODUCT, updateProduct)
}
export function * watchDeleteProduct () {
  yield takeEvery(DELETE_PRODUCT, deleteProduct)
}

export default function * rootSaga () {
  yield all([fork(watchCreateProduct)])
  yield all([fork(watchGetProducts)])
  yield all([fork(watchGetSingleProduct)])
  yield all([fork(watchUpdateProduct)])
  yield all([fork(watchDeleteProduct)])
}
