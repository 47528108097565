import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import AddNewProduct from './AddNewProduct'
import { getAllProducts } from '../../redux/actions'
import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

const User = ({ getAllProducts, allProducts, loading,product }) => {
  const [openModal, setModalOpen] = useState(false)
  console.log(allProducts)

  useEffect(() => {
    getAllProducts()
    // eslint-disable-next-line
  }, [])

  const toggleModal = () => {
    setModalOpen(!openModal)
  }

  return (
    <div>
      <div className='mt-5'>
        <Row>
          <Col className='' sm='12' md='12'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h5 className='mb-0'>Products</h5>
              <button
                onClick={toggleModal}
                className='py-2 px-4 text-white rounded'
                style={{
                  background: '#68402eea'
                }}
              >
                {' '}
                + Add New
              </button>
            </div>
            <table className=' w-100'>
              <tr className='table-head mb-3'>
                <th>Image</th>
                <th>Name</th>
                <th>Category</th>
                <th>Type</th>
                <th>Starting Price</th>
                <th>Bids</th>
                <th>Action</th>
              </tr>

              {allProducts.map(el => {
                return (
                  <tr>
                    <td>
                      <div
                        style={{
                          width: '70px',
                          height: '70px'
                        }}
                      >
                        <img src={el.file} alt='...' width='90px' />
                      </div>
                    </td>
                    <td>{el.name}</td>
                    <td>{el.category}</td>
                    <td>{el.auctionType}</td>
                    <td>{`$${el.startingBidPrice}`}</td>
                    <td>{el.bids.length}</td>
                    <td>
                      <NavLink
                        to={`/admin/product-details/${el._id}`}
                        className='btn user-outline-btn py-1 rounded'
                      >
                        View
                      </NavLink>
                    </td>
                  </tr>
                )
              })}
            </table>
          </Col>
        </Row>
      </div>
      <AddNewProduct modalState={openModal} setModalState={toggleModal} />
    </div>
  )
}
const mapStateToProps = ({ product }) => {
  const { allProducts, loading } = product
  return { allProducts, loading }
}
export default connect(mapStateToProps, { getAllProducts })(User)
