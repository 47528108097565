import React from 'react'
import { NavLink } from 'react-router-dom'

const Sidebar = ({ openSidebar, authorized, toggleSidebar }) => {
  return (
    <div id='mySidenav' className={`sidenav ${openSidebar ? 'w-100' : 'w-0'}`}>
      <div className='mb-2 px-4'>
        <NavLink
          onClick={toggleSidebar}
          style={{
            textDecoration: 'none',
            color: 'white',
            fontSize: '1.1em'
          }}
          className={isActive =>
            isActive ? 'text-white  fw-bold text-underline' : ''
          }
          to='/'
        >
          Home
        </NavLink>
      </div>
      <div className='mb-2 px-4'>
        <NavLink
          onClick={toggleSidebar}
          style={{
            textDecoration: 'none',
            color: 'white',
            fontSize: '1.1em'
          }}
          to='/exhibitions'
          className={isActive =>
            isActive ? 'text-white text-underline fw-bold' : ' text-white'
          }
        >
          Exhibitions
        </NavLink>
      </div>
      <div className='mb-2 px-4'>
        <NavLink
          onClick={toggleSidebar}
          style={{
            textDecoration: 'none',
            color: 'white',
            fontSize: '1.1em'
          }}
          to='/models'
          className={isActive =>
            isActive ? 'text-white text-underline fw-bold' : 'text-white'
          }
        >
          Models
        </NavLink>
      </div>
      <div className='mb-2 px-4'>
        <NavLink
          onClick={toggleSidebar}
          style={{
            textDecoration: 'none',
            color: 'white',
            fontSize: '1.1em'
          }}
          to='/about'
          className={isActive =>
            isActive ? 'text-white text-underline fw-bold' : 'text-white'
          }
        >
         About us
        </NavLink>
      </div>
      <div className='mb-2 px-4'>
        <NavLink
          onClick={toggleSidebar}
          style={{
            textDecoration: 'none',
            color: 'white',
            fontSize: '1.1em'
          }}
          to='/contact'
          className={isActive =>
            isActive ? 'text-white text-underline fw-bold' : 'text-white'
          }
        >
          Contact
        </NavLink>
      </div>
      {!authorized && (
        <div>
          <div className='mb-3'>
            <NavLink
              to='/auth/login'
              className=' btn mx-3 px-5 text-white w-full'
              style={{
                background: '#68402eea',
                color: '#68402eea'
              }}
            >
              Login
            </NavLink>
          </div>
          <div className='mb-3'>
            <NavLink
              to='/auth/register'
              className='btn  px-5 w-full text-white'
              style={{
                background: '#68402eea',
                color: 'white'
              }}
            >
              Create Account
            </NavLink>
          </div>
        </div>
      )}
    </div>
  )
}

export default Sidebar
