import React, {  useEffect } from 'react'
import {  Row,   Form, Col, Button } from 'reactstrap'
import logo from '../../assets/img/TradeMaxi.png'
import { ThreeDots } from 'react-loader-spinner'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { setAuthState, loginUser } from '../../redux/actions'
import createNotification from '../../utils/Notification'

const Signin = ({
  // setAuthState,
  //   loginUser,
  loading,
  loginError,
  message
}) => {
  // const [inputType, setInputType] = useState('password')
  const {
    handleSubmit,
    // register,
    formState: { errors }
  } = useForm()
  const signinUser = values => {
    console.log(values)
    // loginUser(values);
    window.location.href = '/overview'
    // // createNotification()
  }

  useEffect(() => {
    console.log(loginError, message, loading)
    if (loginError.length > 0) {
      createNotification('error', loginError)
    }
    if (message.length > 0) {
      createNotification('info', loginError)
    }
  }, [loginError, message, loading])

  return (
    <div className='auth'>
      <div className='text-center '>
        <img src={logo} alt='...' />
      </div>
      <Row className='gx-0'>
        <Col lg='12' sm='12' className=''>
          <div className='signin-right'>
            <div className='mx-auto  signin-form-wrapper'>
              <div className='signin-logo text-center my-2 mb-4'>
                <img src={logo} alt='ontriv-logo' />
              </div>
              <div className='px-5 py-2 signin-form-div mb-1 mx-auto w-50'>
                <div className='mb-3'>
                  <h3
                    className='text-center mb-2 fontInter'
                    style={{
                      fontWeight: '600',
                      fontSize: '35px'
                    }}
                  >
                    Forgot Password
                  </h3>
                  <p className='text-center'>Enter your email to reset password</p>
                </div>

                <Form onSubmit={handleSubmit(signinUser)}>
                  <div>
                    {/* {errors.email && (
                      <span className='text-danger text-left'>
                        Enter a valid Email
                      </span>
                    )} */}
                  </div>
                  <label>Email</label>
                  <input
                    type='email'
                    name='email'
                    className={`w-100 py-3 px-2 ${
                      errors.email ? 'border-danger' : ''
                    }`}
                    style={{
                      marginBottom: '40px'
                    }}
                    // {...register('email', {
                    //   required: true,
                    //   pattern: {
                    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    //     message: 'invalid email'
                    //   }
                    // })}
                  />
                  {/* {errors.password && (
                    <span className='text-danger text-left'>
                      Enter your password
                    </span>
                  )} */}
                  {/* <div className='password-container'>
                    <div className='password-icon'>
                      {inputType === 'password' ? (
                        <TiEye
                          color='#B1B1B1'
                          size='30px'
                          onClick={() => {
                            setInputType('text')
                          }}
                        />
                      ) : (
                        <IoMdEyeOff
                          color='#B1B1B1'
                          size='30px'
                          onClick={() => {
                            setInputType('password')
                          }}
                        />
                      )}
                    </div>
                    <label>Password</label>
                    <input
                      className={`w-100 py-3 px-2 ${
                        errors.password ? 'border-danger' : ''
                      }`}
                      type={inputType}
                      name='password'
                    //   {...register('password', {
                    //     required: true
                    //   })}
                    />
                  </div> */}

                  
                 
                  <div
                    className='text-center'
                    style={{
                      marginTop: '51px'
                    }}
                  >
                    <Button
                      className='py-3  submit-btn align-items-center'
                      type='submit'
                      disabled={loading}
                      style={{
                        width: '30%',
                        border: 'none',
                        backgroundColor: '#1736B3'
                      }}
                    >
                      {loading ? (
                        <div className='text-center w-100 text-center'>
                          <ThreeDots
                            color='white'
                            height={'12px'}
                            wrapperStyle={{ display: 'block' }}
                          />
                        </div>
                      ) : (
                        'Send'
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { loginError, message, loading } = auth
  return { loginError, message, loading }
}
export default connect(mapStateToProps, { setAuthState, loginUser })(Signin)
