import React from 'react'
import { Outlet } from 'react-router-dom'
import AppLayout from '../components/UserLayout'
import { connect } from 'react-redux'
import decode from 'jwt-decode'

const UserRoute = () => {
  // if (authorized) {
  let authorized = false
  const token = localStorage.getItem('pristineToken')
  // console.log(token)
  if (token) {
    const decodedUser = decode(token)
    const isValid = decodedUser.exp * 1000 > Date.now()
    if (isValid) {
      authorized = true
    } else {
      authorized = false
    }
  } else {
    authorized = false
  }
  console.log(authorized)
  return (
    <AppLayout authorized={authorized}>
      <Outlet />
    </AppLayout>
  )

  //else {
  //     window.location.href = '/auth/login'
  //   }
  // } else {
  //   window.location.href = '/auth/login'
  // }
}

const mapStateToProps = ({ auth }) => {
  const { authorized, message, loading } = auth
  return { authorized, message, loading }
}
export default connect(mapStateToProps, null)(UserRoute)
