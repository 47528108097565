import { all, fork, put, takeEvery, call } from 'redux-saga/effects'
import Axios from '../../utils/Axios'
import Swal from 'sweetalert2'

import {
  MAKE_PAYMENT,
  makePaymentSuccess,
  makePaymentFailed,
  GET_ALL_TRANSACTIONS,
  getAllTransactionsSuccess,
  getAllTransactionsFailed,
  VERIFY_TRANSACTION,
  verifyTransactionSuccess,
  verifyTransactionFailed,
  GET_ANALYTICS,
  getAnalyticsSuccess,
  getAnalyticsFailed
} from '../actions'

function * makePayment (payload) {
  yield console.log(payload)
  try {
    const response = yield Axios.post(`/transaction/${payload.payload.id}`, {
      url: payload.payload.url
    })
    console.log(response)
    if (response.status === 200) {
      // Swal.fire({
      //   title: 'Success',
      //   text: response.data.message,
      //   icon: 'success',
      //   showConfirmButton: true
      // })
      yield put(makePaymentSuccess(response.data))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(makePaymentFailed('error'))
  }
}

function * getAllTransactions () {
  try {
    const response = yield Axios.get(`/transaction`)
    console.log(response)
    if (response.status === 200) {
      yield put(getAllTransactionsSuccess(response.data))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(getAllTransactionsFailed('error'))
  }
}

function * verifyTransaction (payload) {
  console.log(payload.payload)
  try {
    const response = yield Axios.post(
      `/transaction/verify/${payload.payload.id}`,
      { transactionId: payload.payload.transactionId }
    )
    console.log(response)
    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: true
      })
      // yield put(verifyTransactionSuccess(response.data))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(verifyTransactionFailed(error.message))
  }
}

function * getAnalytics () {
  try {
    const response = yield Axios.get(`/analytics`)
    console.log(response)
    if (response.status === 200) {
      yield put(getAnalyticsSuccess(response.data))
    }
  } catch (error) {
    let message
    console.log(error)
    if (error.response) {
      message = error.response.data.message
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = error.response.data.message
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    } else if (error.message) {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        showConfirmButton: true
      })
    }
    yield put(getAnalyticsFailed(error.message))
  }
}

export function * watchMakePayment () {
  yield takeEvery(MAKE_PAYMENT, makePayment)
}
export function * watchGetAllTransactions () {
  yield takeEvery(GET_ALL_TRANSACTIONS, getAllTransactions)
}
export function * watchVerifyTransaction () {
  yield takeEvery(VERIFY_TRANSACTION, verifyTransaction)
}
export function * watchGetAnalytics () {
  yield takeEvery(GET_ANALYTICS, getAnalytics)
}

export default function * rootSaga () {
  yield all([fork(watchMakePayment)])
  yield all([fork(watchGetAllTransactions)])
  yield all([fork(watchVerifyTransaction)])
  yield all([fork(watchGetAnalytics)])
}
