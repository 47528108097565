import React from "react"
// import {
//   // BsFillGridFill,
// BsCalendar2MinusFill} from "react-icons/bs"
// import { IoSettingsSharp, } from "react-icons/io5"
import { RiExchangeFill} from "react-icons/ri"


import {MdDashboard,MdLogout,MdAccountBalanceWallet} from 'react-icons/md'
import {  
  // FaAward,
FaUserFriends } from "react-icons/fa"
// import {AiFillFileText} from "react-icons/ai"
import {RiPieChartFill,
// RiBookFill
} from 'react-icons/ri'

export const sideBarMenu = [
  {
    id: "Dashboard",
    title: "Dashboard",
    type: "item",
    icon: <MdDashboard size={20} />,
    navLink: "/admin/dashboard"
  },

  {
    id: "Users",
    title: "Users",
    type: "item",
    icon: <FaUserFriends size={20} />,
    navLink: "/admin/user-management"

  },

  {
    id: "Products",
    title: "Products",
    type: "item",
    icon: <MdAccountBalanceWallet size={22} />,
    navLink:"/admin/products"
  },

   {
    id: "Bids",
    title: "Bids",
    type: "item",
    icon: <RiExchangeFill size={20} />,
    navLink:'/admin/bids'
  }
 ,

  {
    id: "Models",
    title: "Models",
    icon: <RiPieChartFill size={20} />,
    type: "item",
    navLink: "/admin/models",
  },

 
]


// export const bottomSideBarMenu=[
//   {
//     id: "Logout",
//     title: "Logout",
//     type: "item",
//     navLink:'/auth/login',
//     icon: <MdLogout size={20} />,
//   }
// ]
