import { useState, useEffect } from 'react'
import { CenteredModal } from '../../components/Modal'
import { Row, Col, Button } from 'reactstrap'
import Select from 'react-select'
import { updateProduct } from '../../redux/actions'
import { connect } from 'react-redux'
import { categories, type } from '../../utils/helper'
import { ThreeDots } from 'react-loader-spinner'
import { useForm } from 'react-hook-form'
import { getSingleProduct } from '../../redux/actions'
import { useParams } from 'react-router-dom'

import Axios from 'axios'

const Edit = ({
  modalState,
  setModalState,
  createProduct,
  updating,
  singleProduct,
  updateProduct,
  getSingleProduct,
  product
}) => {
  const [category, setCategory] = useState('')
  const [auctionType, setType] = useState('')
  const [description, setDescription] = useState('')
  const [file, setFile] = useState(null)
  const [changeFile, setChangeFile] = useState(false)
  const [uploading, setUploading] = useState('')

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()

  const { id } = useParams()

  useEffect(
    () => {
      getSingleProduct(id)
    },
    // eslint-disable-next-line
    []
  )

  const cloudinaryUpload = async gile => {
    setUploading(true)
    const fd1 = new FormData()
    fd1.append('file', gile)
    fd1.append('upload_preset', 'fadeniyi')
    const response = await Axios.post(
      'https://api.cloudinary.com/v1_1/fadeniyi/image/upload',
      fd1
    )
    setUploading(false)
    return response.data.url
  }

  const handleProductCreation = async values => {
    console.log(values)
    // const fileUrl = await cloudinaryUpload(file)

    const productDetails = {
      ...values,
      category: category.length > 0 ? category : singleProduct.category,
      auctionType:
        auctionType.length > 0 ? auctionType : singleProduct.auctionType,
      description:
        description.length > 0 ? description : singleProduct.description,
      file: file ? await cloudinaryUpload(file) : singleProduct.file
    }
   updateProduct(id,productDetails)
  }

  return (
    singleProduct && (
      <form onSubmit={handleSubmit(handleProductCreation)}>
        <Row>
          <Col sm='12' md='6' className='mb-2'>
            <label>Name</label>
            <input
              className='w-100 rounded px-2 py-1'
              type='text'
              name='name'
              defaultValue={singleProduct.name}
              style={{
                border: 'solid 1px hsl(0, 0%, 80%)'
              }}
              {...register('name', {
                required: true
              })}
            />
            <div>
              {errors.name && (
                <span className='text-danger text-left'>
                  Enter Product Name
                </span>
              )}
            </div>
          </Col>

          <Col sm='12' md='6' className='mb-2'>
            <label>Bid Price</label>
            <input
              className='w-100 rounded px-2 py-1'
              type='number'
              name='startingBidPrice'
              defaultValue={singleProduct.startingBidPrice}
              style={{
                border: 'solid 1px hsl(0, 0%, 80%)'
              }}
              {...register('startingBidPrice', {
                required: true
              })}
            />
            <div>
              {errors.startingBidPrice && (
                <span className='text-danger text-left'>
                  Enter starting bid price
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm='12' md='6' className='mb-2'>
            <label>Category</label>
            <Select
              options={categories}
              defaultValue={[
                { label: singleProduct.category, value: singleProduct.category }
              ]}
              onChange={e => {
                setCategory(e.value)
              }}
            />
          </Col>
          <Col sm='12' md='6' className='mb-2'>
            <label>Type</label>
            <Select
              options={type}
              onChange={e => {
                setType(e.value)
              }}
              defaultValue={[
                {
                  label: singleProduct.auctionType,
                  value: singleProduct.auctionType
                }
              ]}
            />
          </Col>

          <Col sm='12' md='6' className=''>
            <label>Description</label>
            <textarea
              className='w-100 px-2 rounded'
              rows='5'
              name='description'
              style={{
                border: 'solid 1px hsl(0, 0%, 80%)'
              }}
              defaultValue={singleProduct.description}
              onChange={e => {
                setDescription(e.target.value)
              }}
            />
            <div>
              {errors.description && (
                <span className='text-danger text-left'>Enter description</span>
              )}
            </div>
          </Col>
          <Col sm='12' md='6' className='mb-2'>
            <label>Due Date</label>
            <input
              type='date'
              name='dueDate'
              style={{
                border: 'solid 1px hsl(0, 0%, 80%)'
              }}
              className='w-100 py-1 rounded px-1 py-2'
              {...register('dueDate', {
                required: true
              })}
              defaultValue={singleProduct.dueDate.split('T')[0]}
            />
            <div>
              {errors.dueDate && (
                <span className='text-danger text-left'>Enter due date</span>
              )}
            </div>
          </Col>
          <Col sm='12' md='12' className='mb-2'>
            {changeFile ? (
              <input
                type='file'
                className='mb-0 mt-2'
                onChange={e => {
                  setFile(e.target.files[0])
                }}
              />
            ) : (
              <img
                src={singleProduct.file}
                className='mt-2'
                width='250px'
                height='auto'
              />
            )}

            <div>
              <Button
                className='btn btn-outline-danger mt-2 ml-2'
                style={{
                  background: 'transparent',
                  border: 'solid 1px red',
                  color: 'red'
                }}
                onClick={e => {
                  e.preventDefault()
                  setChangeFile(!changeFile)
                }}
              >
                Change
              </Button>
            </div>
          </Col>

          <Col sm='12' md='3' className='mb-1'>
            <button
              type='submit'
              className='btn w-100 py-2 text-white rounded border-none'
              style={{
                background: '#68402eea'
              }}
            >
              {updating || uploading ? (
                <div className='text-center w-100 text-center'>
                  <ThreeDots
                    color='white'
                    height={'12px'}
                    wrapperStyle={{ display: 'block' }}
                  />
                </div>
              ) : (
                'Save'
              )}
            </button>
          </Col>
        </Row>
      </form>
    )
  )
}

const mapStateToProps = ({ product }) => {
  const { updating, singleProduct } = product
  return { updating, singleProduct }
}

export default connect(mapStateToProps, { getSingleProduct, updateProduct })(
  Edit
)
