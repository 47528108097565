import {
  MAKE_PAYMENT,
  MAKE_PAYMENT_FAILED,
  MAKE_PAYMENT_SUCCESS,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_FAILED,
  GET_ALL_TRANSACTIONS_SUCCESS,
  VERIFY_TRANSACTION,
  VERIFY_TRANSACTION_SUCCESS,
  VERIFY_TRANSACTION_FAILED,
  GET_ANALYTICS,
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_SUCCESS
} from '../actions'

const INIT_STATE = {
  loading: false,
  updating: false,
  creating: false,
  deleting: false,
  allTransactions: [],
  public_key: null,
  analytics: null
}

const productReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAKE_PAYMENT:
      return { ...state, loading: true }
    case MAKE_PAYMENT_SUCCESS:
      return { ...state, loading: false, public_key: action.payload.link.data }
    case MAKE_PAYMENT_FAILED:
      return { ...state, loading: false }

    case GET_ALL_TRANSACTIONS:
      return { ...state, loading: true }
    case GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTransactions: action.payload.data.data
      }
    case GET_ALL_TRANSACTIONS_FAILED:
      return { ...state, loading: false }

    case VERIFY_TRANSACTION:
      return { ...state, loading: true }
    case VERIFY_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case VERIFY_TRANSACTION_FAILED:
      return { ...state, loading: false }

    case GET_ANALYTICS:
      return { ...state, loading: true }
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        analytics: action.payload.data.data
      }
    case GET_ANALYTICS_FAILED:
      return { ...state, loading: false }

    default:
      return { ...state }
  }
}

export default productReducer
