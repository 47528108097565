import React, { useEffect, useState } from 'react'
import { Col, Row, Card } from 'reactstrap'
import { BiSearchAlt2 } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
// import Footer from '../../components/UserLayout/Footer'
import { getAllProducts } from '../../redux/actions'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

const Exhibition = ({ getAllProducts, allProducts, loading }) => {
  const [products, setProduct] = useState([])
  const params = useParams()
  console.log(params)

  useEffect(() => {
    getAllProducts()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let params = new URL(document.location).searchParams
    let search = params.get('search')
    console.log(search)
    if (search) {
      setProduct(
        allProducts.filter(
          el =>
            el.auctionType === 'Trending' &&
            (el.name.toLowerCase() == search ||
              el.category.toLowerCase() === search)
        )
      )
    } else {
      setProduct(allProducts.filter(el => el.auctionType === 'Trending'))
    }

    // eslint-disable-next-line
  }, [allProducts])

  return (
    <>
      <div className='exhibition px-md-5 px-3'>
        <div className='exhibition-sidebar rounded py-3 d-none d-md-block'>
          <div className='d-flex align-items-center justify-content-center'>
            <input
              type='text'
              placeholder='Search items. . .'
              className='p-2 outline-none border-none rounded-left'
              style={{
                background: '#f3f4f6',
                border: 'none',
                outline: 'none'
              }}
            />
            <div className=' btn btn-dark rounded-right border-none '>
              <BiSearchAlt2 className='text-white' />
            </div>
          </div>
          <div className='px-3'>
            <h5 className='mt-2 mb-1'>Categories</h5>

            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>Rings</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(el => el.auctionType === 'Trending')
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>Chains</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(el => el.category.toLowerCase() === 'chains')
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>Trinkets</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(el => el.category.toLowerCase() === 'trinkets')
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>Necklace</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(el => el.category.toLowerCase() === 'necklace')
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>Earrings</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(el => el.category.toLowerCase() === 'earrings')
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>Wrist watches</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(
                        el => el.category.toLowerCase() === 'wristwatches'
                      )
                  )
                }}
              />
            </div>
          </div>

          <div className='px-3 pt-4'>
            <h5 className='mt-2 mb-1'>Prices</h5>

            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>{'< $5,000'}</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(el => el.startingBidPrice <= 5000)
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>{'$5,000 - $10,000'}</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(
                        el =>
                          el.startingBidPrice >= 5000 &&
                          el.startingBidPrice <= 10000
                      )
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>{'$10,000 - $20,000'}</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(
                        el =>
                          el.startingBidPrice ===
                            el.startingBidPrice >= 10000 &&
                          el.startingBidPrice <= 20000
                      )
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>{'$20,000 - $50,000'}</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(
                        el =>
                          el.startingBidPrice ===
                            el.startingBidPrice >= 20000 &&
                          el.startingBidPrice <= 50000
                      )
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>{'$50,000 - $100,000'}</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(
                        el =>
                          el.startingBidPrice ===
                            el.startingBidPrice >= 50000 &&
                          el.startingBidPrice <= 100000
                      )
                  )
                }}
              />
            </div>
            <div className='d-flex align-items-center mb-1 justify-content-between'>
              <label>{'> $100,000'}</label>
              <input
                type='checkbox'
                className='mb-'
                onClick={e => {
                  setProduct(
                    allProducts
                      .filter(el => el.auctionType === 'Trending')
                      .filter(
                        el =>
                          el.startingBidPrice === el.startingBidPrice > 100000
                      )
                  )
                }}
              />
            </div>
          </div>
        </div>
        <div className='exhibtion-content'>
          <Row className='w-100'>
            {products.map(el => {
              return (
                <Col sm='12' md='4' className='mb-4 ' style={{
                  minWidth:'305px'
                }}>
                  <NavLink to={`/product-details/${el._id}`}>
                    <Card className='overflow-hidden zoom-container w-100 '>
                      <img
                        className='zoom w-100'
                        src={el.file}
                        alt='...'
                        height='300px'
                      />
                      <div className=''>
                        <div className='product-details text-left py-3 px-2 ml-5 w-100'>
                          <h5 style={{
                            fontSize:"18px"
                          }}>{el.name}</h5>
                          <p className='mb-0'>
                            starting bid price:{' '}
                            <span className='fw-bold'>
                              $ {el.startingBidPrice}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Card>
                  </NavLink>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  )
}

const mapStateToProps = ({ product }) => {
  const { allProducts, loading } = product
  return { allProducts, loading }
}
export default connect(mapStateToProps, { getAllProducts })(Exhibition)
