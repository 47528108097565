import React, { useState } from 'react'
import { Row, Col, Card } from 'reactstrap'
import Select from 'react-select'
import Footer from '../../components/UserLayout/Footer'
import { IoIosMail } from 'react-icons/io'
import { ImLinkedin } from 'react-icons/im'
import { MdEmail, MdLocationOn } from 'react-icons/md'
import { SiMinutemailer } from 'react-icons/si'
import { AiFillInstagram } from 'react-icons/ai'
import { BsTelephonePlusFill } from 'react-icons/bs'
import { FaWhatsappSquare,FaPhoneSquareAlt } from 'react-icons/fa'


import Axios from '../../utils/Axios'
import Swal from 'sweetalert2'

const Models = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      if (name.length > 0 && message.length > 0 && email.length > 0) {
        const details = {
          name,
          message,
          email
        }
        console.log(details)

        const response = await Axios.post('/mail', details)
        console.log(response)
        if (response.status === 200) {
          setLoading(false)
          Swal.fire({
            title: 'Success',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: true
          })
        } else {
          setLoading(true)
        }
      }
    } catch (err) {
      setLoading(false)
      console.log(err.response)
      Swal.fire({
        title: 'Error',
        text: err.message,
        icon: 'error',
        showConfirmButton: true
      })
    }
  }

  return (
    <div className='contact-section'>
      <div className='h-100vh hero-section'></div>
      <div className='mt-3 px-md-5 px-1 mb-5'>
        <Row>
          <Col sm='12' md='8'>
            <Card className='my-3 px-5 pt-3 pb-3 mt-5 mx-'>
              <form className='d-flex flex-column justify-content-center model-form my-3'>
                <Row className='mb-2'>
                  <Col sm='12' md='12'>
                    <label>Name</label>
                    <input
                      style={{
                        border: '1px solid black'
                      }}
                      onChange={e => setName(e.target.value)}
                      placeholder=''
                      className='rounded mb-2 w-100 py-2 px-2'
                    />
                  </Col>

                  <Col sm='12' md='12'>
                    <label>Email</label>
                    <input
                      style={{
                        border: '1px solid black'
                      }}
                      placeholder=''
                      onChange={e => setEmail(e.target.value)}
                      type='email'
                      className='rounded mb-2 w-100 py-2 px-2'
                    />
                  </Col>
                </Row>

                <Row className='mb-2'>
                  <Col sm='12' md='12'>
                    <label>Message</label>
                    <textarea
                      placeholder=''
                      className='rounded mb-2 w-100 py-2 px-2'
                      cols='30'
                      style={{
                        height: '200px'
                      }}
                      onChange={e => setMessage(e.target.value)}
                    />
                  </Col>
                </Row>
                <button
                  disabled={loading}
                  className='btn btn-dark'
                  onClick={handleSubmit}
                >
                  {loading ? 'Submiting...' : 'Submit'}
                </button>
              </form>
            </Card>
          </Col>
          <Col sm='12' md='4'>
            <Card className='my-3 px-5 pt-3 pb-3 mt-5'>
              <div>
                <h3 className='text-center'>Get in Touch</h3>
                <div>
                  <div className='d-flex flex-row align-gap-3 mb-4 align-items-center mt-3'>
                  <div>
                    <MdEmail
                      className='text-dark text-xl mb-0 '
                      fontSize='25px'
                      style={{ marginRight: '16px' }}
                    />
                    </div>
                    <a
                      className=' text-dark  mb-2'
                      href='mailto:info@pristinepiecesauctions.com'
                    >
                      info@pristinepiecesauctions.com
                    </a>
                  </div>
                  <div className='d-flex flex-row align-gap-3 mb-4 align-items-center'>
                    <FaPhoneSquareAlt
                      className='text-dark text-xl mb-0 '
                      fontSize='25px'
                      style={{ marginRight: '16px' }}
                    />
                    <p className=' text-dark mb-1'>+(515) 599-0234</p>
                  </div>
                  <div className='d-flex flex-row align-gap-3 mb-4 align-items-center'>
                    <FaWhatsappSquare
                      className='text-dark text-xl mb-0 '
                      fontSize='30px'
                      style={{ marginRight: '16px' }}
                    />
                    <p className=' text-dark mb-1'>+(208) 953-1676</p>
                  </div>

                  <div className='d-flex flex-row align-gap-3 mb-4 align-items-center'>
                    <AiFillInstagram
                      className='text-dark text-xl mb-0 '
                      fontSize='30px'
                      style={{ marginRight: '16px' }}
                    />
                    <a
                      className=' text-dark mb-1'
                      href='https://www.instagram.com/pristinepieces_/?igshid=YmMyMTA2M2Y%3D'
                    >
                      Pristine Pieces
                    </a>
                  </div>
                  {/* <div className='d-flex flex-row align-gap-3 mb-4 '>
                    <MdLocationOn
                      className='text-dark text-xl mb-0 '
                      fontSize='30px'
                      style={{ marginRight: '16px' }}
                    />
                    <p className=' text-dark mb-1'>
                      New York Office 777 United Nations Plaza, 7th Floor New
                      York, NY 10017
                    </p>
                  </div> */}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  )
}

export default Models
