import React from 'react'
import product from '../../assets/img/pp19.JPG'

const UserDetails = () => {
  return (
    <div>
      <h3>User Details</h3>
      <div className='mb-3'>
        <div>
          Name: <span className='fw-bold'>John Doe</span>
        </div>
        <div>
          Email: <span className='fw-bold'>JohnDoe@gmail.com</span>
        </div>
      </div>

      <div>
        <div className='fw-bold'>Transactions</div>
        <table className=' w-100'>
          <tr className='table-head mb-3'>
            <th>Product</th>
            <th>Bid Price</th>
            <th>Price Sold</th>
            <th>Buyer</th>
            <th>Date</th>
          </tr>

          <tr>
            <td>Salvatore Ferragamo</td>
            <td>$500</td>
            <td>$2,000</td>
            <td>John Doe</td>
            <td>23/05/2022</td>
          </tr>

          <tr>
            <td>Lous Vuitton</td>
            <td>$500</td>
            <td>$2,000</td>
            <td>John Doe</td>
            <td>23/05/2022</td>
          </tr>

          <tr>
            <td>Gucci</td>
            <td>$500</td>
            <td>$2,000</td>
            <td>John Doe</td>
            <td>23/05/2022</td>
          </tr>

          <tr>
            <td>Salvatore Ferragamo</td>
            <td>$500</td>
            <td>$2,000</td>
            <td>John Doe</td>
            <td>23/05/2022</td>
          </tr>
        </table>
      </div>

      <div className='mt-3 f'>
        <div className='fw-bold'>Bids</div>
        <table className=' w-100'>
          <tr className='table-head mb-3'>
            <th>Image</th>
            <th>Name</th>
            <th>Category</th>
            <th>Starting Price</th>
            <th>Bid Price</th>
            <th>Status</th>
          </tr>

          <tr>
            <td>
              <div
                style={{
                  width: '70px',
                  height: '70px'
                }}
              >
                <img src={product} alt='...' width='70px' />
              </div>
            </td>
            <td>Salvatore Ferragamo</td>
            <td>Rings</td>
            <td>$5,000</td>
            <td>$70,000</td>
            <td className='text-success'>Approved</td>
          </tr>

          <tr>
            <td>
              <div
                style={{
                  width: '70px',
                  height: '70px'
                }}
              >
                <img src={product} alt='...' width='70px' />
              </div>
            </td>
            <td>Salvatore Ferragamo</td>
            <td>Rings</td>
            <td>$5,000</td>
            <td>$70,000</td>
            <td className='text-warning'>Pending</td>
          </tr>

          <tr>
            <td>
              <div
                style={{
                  width: '70px',
                  height: '70px'
                }}
              >
                <img src={product} alt='...' width='70px' />
              </div>
            </td>
            <td>Salvatore Ferragamo</td>
            <td>Rings</td>
            <td>$5,000</td>
            <td>$70,000</td>
     
              <td className='text-danger'>Declined</td>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default UserDetails
