import React from 'react'
import { Button } from 'reactstrap'
import { NavLink } from 'react-router-dom'

const Transactions = () => {
  return (
    <div className='transaction-section'>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <input
            type='radio'
            id='sent'
            name='transaction'
            value='sent'
            style={{
              border: '#1736B3'
            }}
          />
          <label
            for='sent'
            className='ml-2'
            style={{
              marginLeft: '4px',
              marginRight: '8px'
            }}
          >
            Sent
          </label>

          <input type='radio' id='recieved' name='transaction' value='sent' />
          <label
            for='recieved'
            className='ml-2'
            style={{
              marginLeft: '4px'
            }}
          >
            Recieved
          </label>
        </div>

        <div>
          <NavLink to='/create-transactions'>
            <Button
              className='btn btn-primary'
              style={{
                background: '#1736B3',
                border: 'none'
              }}
            >
              +Create Transactions
            </Button>
          </NavLink>
        </div>
      </div>

      <div className='mt-2'>
        <table className=' w-100'>
          <tr className='table-head mb-3'>
            <th>ID</th>
            <th>Title</th>
            <th>Date Created</th>
            <th>Due Date</th>
            <th>Receipient</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Action</th>
          </tr>

          <tr>
            <td>ND3348</td>
            <td>Shoe Purchase</td>
            <td>12/04/22</td>
            <td>12/04/22</td>
            <td>Dennis Michael</td>
            <td>2000</td>
            <td>
              <p
                className='mb-0'
                style={{
                  color: '#1736B3'
                }}
              >
                Successful
              </p>
            </td>
            <td className=''>
              <Button
                className='btn transaction-outline-danger'
                style={{
                  background: 'transparent',
                  color: '#EE0000',
                  border: 'solid 1px #EE0000'
                }}
              >
                Delete
              </Button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default Transactions
